import bibanBaseRequest from "../_core";

const bibanVisitorServices = {
  getSpecialMajors: () => bibanBaseRequest.get("/get/majorst?type=smajor"),
  getCities: () => bibanBaseRequest.get("/getcity?type=city"),
  getSectors: () => bibanBaseRequest.get("/getsectors?type=sector"),
  getCompanySpecialMajors: () => bibanBaseRequest.get("/getspec?type=sMajor"),
  getAllJobCategory: () => bibanBaseRequest.get("/get/allJobCategory?type=category"),
};

export const getmatchedusers = (data) =>
  bibanBaseRequest.post("/getmatchedusers", data);
export const baybanrigester = (data) =>
  bibanBaseRequest.post("/baybanrigester", data);
export const baybanvalidate = (data) =>
  bibanBaseRequest.post("/baybanvalidate", data);
export const resendOtp = (data) => bibanBaseRequest.post("/resendotp", data);

export default bibanVisitorServices;
