import React from "react";
import "./style.scss";
import { Row, Col, Input, DatePicker, Radio, Modal, Select } from "antd";
import moment from "moment";
import Footer from "../Footer";
import LoginNavbar from "../Header/LoginNavbar";
import TempForm from "../../services/newForm";

import _ from "lodash";
const { addSalamInfo } = TempForm;

class SalamForm extends React.Component {
    state = {
        visible: false,
        error: false,
    };

    handleChange = (e) => {
        const { value, name } = e.target;
        console.log(name);
        this.setState({
            [name]: value,
        });
    };

    handleDateChange = (birthDate) => {
        this.setState({
            birthDate: moment(birthDate),
        });
    };

    handleSelectChange = (value, option) => {
        console.log("option", option);
        this.setState({
            ...this.state,
            [option.props.name]: option.key,
        });
    };

    handleMultipleSelectChange = (value, option) => {
        this.setState({
            company: value,
        });
    };

    handleSelectChangeEdu = (value, option) => {
        this.setState({
            certificate: value,
        });
    };

    handleSelectGeneralMajor = (value, option) => {
        this.setState({
            generalMajor: value,
        });
    };

    handleSelectCities = (value, option) => {
        this.setState({
            city: value,
        });
    };

    add = async () => {
        const {
            name,
            gender,
            NBHD,
            city,
            mobile,
            email,
            avilableCar,
            timeToDelivier,
            areas,
            experience,
            isSaudi,
            employer,
            isWork,
            company,
            certificate,
            generalMajor,
            ptwayMember,
            mobileOS,
            social,
        } = this.state;

        console.log(this.state);

        if (
            (!name ||
                !gender ||
                !NBHD ||
                !city ||
                !mobile ||
                !/^\d{10}$/.test(mobile) ||
                !email ||
                !avilableCar ||
                !timeToDelivier ||
                !experience,
                !isWork,
                !certificate ||
                !areas ||
                !generalMajor ||
                !ptwayMember ||
                !mobileOS ||
                !social)
        ) {
            this.setState({
                error: true,
            });
        } else {
            await addSalamInfo(this.state);
            this.setState({
                visible: true,
            });
        }
    };
    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        const dateFormat = "DD/MM/YYYY";
        const { error } = this.state;

        const cities = ["الرياض", "الشرقية", "جدة"];
        const companies = ["ساري"];

        const certificate = [
            "ثانوية عامة",
            "دبلوم",
            "بكالوريوس",
            "دبلوم عالي",
            "ماجستير",
            "دكتوراه",
        ];

        const generalMajors = [
            "ثانوية عامة",
            "طب",
            "إدارة مالية و مصرفية",
            "تسويق",
            "تصاميم وفنون",
            "صحة عامة",
            "صيدلة",
            "إدارة الأعمال",
            "هندسة",
            "سياحة و الآثار",
            "كلية المجتمع",
            "علوم طبية تطبيقية",
            "علوم",
            "إلكترونيات",
            "أدآب",
            "هندسة تطبيقية",
            "إدارة الاعمال التطبيقية",
            "علوم الحاسب",
            "خدمات طبية طارئة",
            "تربية",
            "علوم الرياضة و النشاط البدني",
            "إدارة الموارد البشرية",
            "عمارة و تخطيط",
            "إدارة إمال التأمين",
            "علوم الحاسب و المعلومات",
            "إقتصاد منزلي",
            "إعلام",
            "علاقات عامة",
            "شريعة",
            "إتصالات و شبكات",
            "طب الأسنان",
            "تمريض",
            "علوم الأغذية و الزراعة",
            "لغات و ترجمة",
            "حقوق و علوم السياسة",
            "علوم الحاسب التطبيقي",
        ];

        return (
            <React.Fragment>
                <LoginNavbar />
                <Row>
                    <Col md={4}></Col>
                    <Col md={15} className="job-new-form">
                        <h2>هذا وقتك، سجل معنا!</h2>
                        {/******************** Personal Info ******************** */}
                        <label>الاسم ثلاثي</label>
                        <Input onChange={this.handleChange} name="name" />
                        {error && !this.state.name && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <div className="pers-info">
                            <div>
                                <h4>تاريخ الميلاد</h4>
                                <DatePicker
                                    placeholder={dateFormat}
                                    format={dateFormat}
                                    className="form-date"
                                    onChange={this.handleDateChange}
                                />
                                {error && !this.state.birthDate && (
                                    <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                                )}
                            </div>
                            <div>
                                <h4>الجنس</h4>
                                <Radio.Group
                                    onChange={this.handleChange}
                                    className="radio-select"
                                    name="gender"
                                >
                                    <Radio.Button value="ذكر">ذكر</Radio.Button>
                                    <Radio.Button value="أنثى">أنثى</Radio.Button>
                                </Radio.Group>
                                {error && !this.state.gender && (
                                    <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                                )}
                            </div>
                        </div>
                        <br />
                        <label>رقم الجوال</label>
                        <Input
                            placeholder="05xxxxxxxx"
                            maxlength="10"
                            onChange={this.handleChange}
                            name="mobile"
                        />
                        {error && !this.state.mobile && (
                            <span style={{ color: "red" }}> هذا الحقل مطلوب</span>
                        )}
                        {error && !/^\d{10}$/.test(this.state.mobile) && (
                            <span style={{ color: "red" }}>
                                {" "}
                                يجب آلا يزيد أو يقل عن 10 أرقام
                            </span>
                        )}
                        <br />
                        <br />
                        <label>البريد الإلكتروني</label>
                        <Input onChange={this.handleChange} name="email" type="email" />
                        {error && !this.state.email && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>الجنسية</label>
                        <br /> <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select"
                            name="isSaudi"
                        >
                            <Radio.Button value="سعودي">سعودي</Radio.Button>
                            <Radio.Button value="غير سعودي">غير سعودي</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.isSaudi && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>ماهي جنسيتك؟</label>{" "}
                        <span style={{ fontSize: "14px", color: "#d0d0d0" }}>
                            في حال الإجابة بغير سعودي
                        </span>
                        <br />
                        <Input onChange={this.handleChange} name="nationality" />
                        <br />
                        <br />
                        <label>هل توجد لديك خبرة في العمل كمندوب مبيعات؟</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select select-mob"
                            name="experience"
                        >
                            <Radio.Button value="لا توجد خبرة">لا توجد خبرة</Radio.Button>
                            <Radio.Button value="سنة - 3 سنوات">سنة - 3 سنوات</Radio.Button>
                            <Radio.Button value="3 - 5 سنوات">3 - 5 سنوات</Radio.Button>
                            <Radio.Button value="5 سنوات و أكثر">
                                {" "}
                                5 سنوات و أكثر
                            </Radio.Button>
                        </Radio.Group>
                        {error && !this.state.experience && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>اسم الجهه التي عملت لديهم</label> <br />
                        <Input onChange={this.handleChange} name="employer" />
                        <br />
                        <br />
                        <label>الشهادة التعليمية</label>
                        <br />
                        <br />
                        <Select
                            className="input-field"
                            onChange={this.handleSelectChangeEdu}
                            style={{ width: "100%" }}
                            //mode={"multiple"}
                            showArrow={true}
                        >
                            {_.isArray(certificate)
                                ? certificate.map((elm) => {
                                    return (
                                        <Select.Option value={elm} key={elm} name="certificate">
                                            {elm}
                                        </Select.Option>
                                    );
                                })
                                : ""}
                        </Select>
                        {error && !this.state.certificate && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>التخصص العام</label>
                        <br />
                        <br />
                        <Select
                            className="input-field"
                            onChange={this.handleSelectGeneralMajor}
                            style={{ width: "100%" }}
                            // mode={"multiple"}
                            showArrow={true}
                        >
                            {_.isArray(generalMajors)
                                ? generalMajors.map((elm) => {
                                    return (
                                        <Select.Option value={elm} key={elm} name="generalMajor">
                                            {elm}
                                        </Select.Option>
                                    );
                                })
                                : ""}
                        </Select>
                        {error && !this.state.generalMajor && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <div className="location-">
                            <div>
                                <br />
                                <label>المدينة:</label>
                                <br />
                                <Select
                                    className="input-field"
                                    onChange={this.handleSelectCities}
                                    style={{ width: "100%" }}
                                    showArrow={true}
                                >
                                    {_.isArray(cities)
                                        ? cities.map((elm) => {
                                            return (
                                                <Select.Option value={elm} key={elm} name="city">
                                                    {elm}
                                                </Select.Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                                {error && !this.state.city && (
                                    <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                                )}
                            </div>
                            <div>
                                <label>الحي السكني</label>
                                <br />
                                <Input onChange={this.handleChange} name="NBHD" />
                                {error && !this.state.NBHD && (
                                    <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                                )}
                                <br />
                            </div>
                            <br />
                            <label>المناطق في المدينة التي تستطيع تغطيتها</label>
                            <br />
                            <br />
                            <Radio.Group
                                onChange={this.handleChange}
                                className="radio-select select-mob"
                                name="areas"
                            >
                                <Radio.Button value="الكل">الكل</Radio.Button>
                                <Radio.Button value="وسط-المدينة">وسط المدينة</Radio.Button>
                                <Radio.Button value="شمال-المدينة">شمال المدينة</Radio.Button>
                                <Radio.Button value="جنوب-المدينة">جنوب المدينة</Radio.Button>
                                <Radio.Button value="شرق المدينة">شرق المدينة</Radio.Button>
                                <Radio.Button value="غرب المدينة"> غرب المدينة</Radio.Button>
                            </Radio.Group>
                            {error && !this.state.areas && (
                                <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                            )}
                        </div>
                        <br />
                        <br />
                        <label>هل تتوفر لديك سيارة؟</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select"
                            name="avilableCar"
                        >
                            <Radio.Button value="نعم">نعم</Radio.Button>
                            <Radio.Button value="لا">لا</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.avilableCar && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>هل أنت على رأس عمل؟</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select"
                            name="isWork"
                        >
                            <Radio.Button value="نعم">نعم</Radio.Button>
                            <Radio.Button value="لا">لا</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.isWork && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>نظام تشغيل الجوال</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select"
                            name="mobileOS"
                        >
                            <Radio.Button value="android">Android</Radio.Button>
                            <Radio.Button value="ios">iOS</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.mobileOS && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>هل أنت مسجل في منصة PTway؟</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select"
                            name="ptwayMember"
                        >
                            <Radio.Button value="نعم">نعم</Radio.Button>
                            <Radio.Button value="لا">لا</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.ptwayMember && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <label>كيف سمعت عن PTway؟</label>
                        <br />
                        <br />
                        <Radio.Group
                            onChange={this.handleChange}
                            className="radio-select socail-select"
                            name="social"
                        > <Radio.Button value=" الكليات التقنية ">
                                الكليات التقنية
                            </Radio.Button>
                            <Radio.Button value="جامعة الملك سعود">
                                جامعة الملك سعود
                            </Radio.Button>
                            <Radio.Button value="snapchat">Snapchat</Radio.Button>
                            <Radio.Button value="Twitter">Twitter</Radio.Button>
                            <Radio.Button value="Whatsapp">Whatsapp</Radio.Button>
                            <Radio.Button value="صديق">صديق</Radio.Button>
                        </Radio.Group>
                        {error && !this.state.social && (
                            <span style={{ color: "red" }}>هذا الحقل مطلوب</span>
                        )}
                        <br />
                        <br />
                        <button className="job-form-btn" onClick={this.add}>
                            ارسال
                        </button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Footer />
                <Modal
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <div className="success-modal">
                        <h2>تم تسجيلك في المبادرة، وسيتم التواصل معك في حال ترشيحك.</h2>
                        <br />
                        <h3 style={{ color: "#049ad0" }}>
                            في حال لم تكن مسجل معنا، سجل الآن!
                        </h3>
                        <br />
                        <button>
                            <a href="https://www.ptway.net/user/login">
                                <span className="coloor">سجل في PTway</span>
                            </a>
                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SalamForm;