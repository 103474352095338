import * as yup from "yup";

export default yup.object().shape({
  fullName: yup
    .string("الاسم الأول يجب أن يكون نص")
    .required("الاسم الأول مطلوب"),
  mobile: yup
    .string("رقم الجوال يجب أن يكون نص")
    .min(10, "رقم الجوال يجب أن يكون 10 أرقام")
    .max(10, "رقم الجوال يجب أن يكون 10 أرقام")
    .required("رقم الجوال مطلوب"),
  email: yup
    .string("البريد الالكتروني يجب أن يكون نص")
    .email("البريد الالكتروني يجب أن يكون بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
  selectedJobs: yup
    .array()
    .required("الوظيفة مطلوبة")
    .min(1, "الوظيفة مطلوبة"),
  cv: yup
    .mixed()
    .required("السيرة الذاتية مطلوبة")
    .test("fileFormat", "الملف يجب أن يكون بصيغة pdf", (value) => {
      return value && ["application/pdf"].includes(value.type);
    })
    .test("fileSize", "الملف كبير جدا", (value) => {
      // max is 1mb
      return value && value.size <= 1000000;
    }),
});
