import * as yup from "yup";

const validationSchema = yup.object().shape({
  project: yup.string().required("هذا الحقل مطلوب"),
  contract: yup.string().required("هذا الحقل مطلوب"),
  // jobDetails: yup.string().required("هذا الحقل مطلوب"),
  // need: yup.string().required("هذا الحقل مطلوب"),
  personalSkills: yup.array().required("هذا الحقل مطلوب").min(1, "يجب اختيار مهارة واحدة على الأقل"),
  gender: yup.string().required("هذا الحقل مطلوب"),
  country: yup.string().required("هذا الحقل مطلوب"),
  city: yup.string().required("هذا الحقل مطلوب"),
  jobCategory: yup.string().required("هذا الحقل مطلوب"),

  workDays: yup
    .number()
    .min(1, "يجب ان يكون عدد أيام العمل بين 1 و 59")
    .max(59, "يجب ان يكون عدد أيام العمل بين 1 و 59")
    .required("هذا الحقل مطلوب"),
  workHours: yup
    .number()
    .min(1, "يجب ان يكون عدد ساعات العمل بين 1 و 12")
    .max(12, "يجب ان يكون عدد ساعات العمل بين 1 و 12")
    .required("هذا الحقل مطلوب"),
  salary: yup.number().min(100, "الحد اليومي الأدنى هو 100"),
  jobDescription: yup.string().required("هذا الحقل مطلوب"),
  jobTitle: yup.string().required("هذا الحقل مطلوب"),
  required_Number: yup
    .number()
    .min(1, "يجب ان يكون العدد الطلوب  بين 1 و 7")
    .max(7, "يجب ان يكون العدد المطلوب  بين 1 و 7")
    .required("هذا الحقل مطلوب"),
});

export default validationSchema;
