import React from "react";
import { Row } from "antd";
import ptwayLogo from "../../images/PTway_Logo.svg";
import { withTranslation } from "react-i18next";
import history from "../../_core/history";
import { Link } from "react-router-dom";

const LoginNavbar = () => {
  return (
    <React.Fragment>
      <Row
        className="navbar"
        style={{
          justifyContent: "flex-start ",
        }}
      >
        <div className="navbar-cont">
          <img
            src={ptwayLogo}
            alt="PTway_Logo"
            onClick={() => history.push("/")}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      </Row>
      <Row className="drawer-mobile">
        <img
          src={ptwayLogo}
          alt="PTway_Logo"
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        />
      </Row>

      {window.location.href.includes("user") && (
        <div className="announcement">
        <p>القسم الجديد متاح الآن ، انقر هنا لمعرفة المزيد</p>
        <button>
          <Link to="/support">ادعم مهنتك</Link>
        </button>
      </div>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(LoginNavbar);
