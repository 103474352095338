import React from "react";
import { Modal, Input } from "antd";
import skillImg from "../../images/skill.svg";

const FinanceSkillModal = (props) => {
  return (
    <Modal
      visible={props.postFinancePopup}
      onCancel={props.closable}
      footer={false}
    >
      <div className="new-project">
        <div>
          <img src={skillImg} className="modal-img" />

          <h2 className="p-heading">دعم المهارات</h2>
          <p className="p-description financ-content">
            برنامج موجه خصيصًا لكل صاحب مهارة متخصصة، أو هواية شغوف فيها، وتخصص
            مهتم فيه! يرغب تحويل هذا الشغف إلى مهنة أو عمل خاص به، يزيد من دخله
            ويطور من مسيرته المهنية.
          </p>

          <div className="p-description financ-content">
            <p className="">
              نحن معك نشارك أول الخطوة!
              <br />
              من خلال تقديم التمويل النقدي اللازم لاحتياجاتك! <br /> من توفير
              الأدوات أو الدورات المساندة التي تمكّنك من ممارسة أعمالك مع
              الشركات المسجلة في المنصة.
            </p>
          </div>
        </div>

        <div className="p-description financ-content">
          <p className="p-financ-content"> الفئة المستهدفة:</p>
          <ul>
            <li>الطلاب</li>
            <li>الخريجين</li>
            <li>المتفرغين</li>
            <li>الموظفين</li>
          </ul>

          <p className="p-financ-content"> شروط الحصول على تمويل:</p>
          <ul>
            <li>الموظفين براتب لا يزيد عن 20 ألف ريال</li>
            <li>الحد الأقصى لتمويل العمل الحر النقدي 120,000 ريال</li>
            <li>عمر المتقدم لا يتجاوز 65 عام</li>
          </ul>

          <p className="p-financ-content"> أهداف الدعم:</p>
          <ul>
            <li>
              تمثيل دور Ptway المسؤولة بتمكين حلول التوظيف حسب المهارة و
              الهواية.
            </li>
            <li>
              دخل أساسي أو دخل إضافي لأصحاب المهارات،الهوايات، التخصصات،الحرف.
            </li>
            <li>تعزيز دور الشباب في بناء مشاريع خاصة بهم.</li>
            <li>خفض نسبة البطالة.</li>
          </ul>
        </div>

        <button className="new-project-btn">
          <a
            href="https://len.sdb.gov.sa/Guest/Login.aspx?key=04ab6080-87c1-4456-aef7-5ed7bdd357cd"
            className="financ-btn"
          >
            تقدم بطلب الدعم الآن
          </a>
        </button>
      </div>
    </Modal>
  );
};

export default FinanceSkillModal;
