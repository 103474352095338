import * as yup from "yup";

const validationSchema = yup.object().shape({
  workDays: yup
    .number()
    .min(1, "يجب ان يكون عدد أيام اكثر من يوم واحد على الأقل")
    .max(7, "يجب ان يكون عدد أيام العمل أقل من 7")
    .required("هذا الحقل مطلوب"),

  workHours: yup
    .number()
    .min(1, "يجب ان يكون عدد ساعات العمل بين 1 و 6")
    .max(6, "يجب ان يكون عدد ساعات العمل بين 1 و 6")
    .required("هذا الحقل مطلوب"),
  salary: yup.number().min(1500, "الحد الشهري الأدنى هو 1500"),
  project: yup.string().required("هذا الحقل مطلوب"),
  jobCategory: yup.string().required("هذا الحقل مطلوب"),
  jobTitle: yup.string().required("هذا الحقل مطلوب"),
  gender: yup.string().required("هذا الحقل مطلوب"),
  // date: yup.string().required("هذا الحقل مطلوب"),
  required_Number: yup
    .number()
    .min(1, "يجب ان يكون العدد الطلوب  بين 1 و 7")
    .max(7, "يجب ان يكون العدد المطلوب  بين 1 و 7")
    .required("هذا الحقل مطلوب"),
  personalSkills: yup
    .array()
    .required("هذا الحقل مطلوب")
    .min(1, "يجب اختيار مهارة واحدة على الأقل"),
  jobDescription: yup.string().required("هذا الحقل مطلوب"),
  country: yup.string().required("هذا الحقل مطلوب"),
  city: yup.string().required("هذا الحقل مطلوب"),
});

export default validationSchema;
