import React from "react";
import "./style.scss";
import MobileAndHand from "../../../images/mobileAndHand.svg";
import brand from "../../../images/brand.svg";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Form, Input, Button, Select, notification } from "antd";
import bibanVisitorServices from "../../../services/bibanVisitor";

const INITIAL_VALUES = {
  project: "",
  sector: "",
  major: "",
  city: "",
  gender: "",
  jobCategory: "",
};

function EmptyComponent({ text }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="rgba(0, 0, 0, 0.25)"
        version="1.1"
        width="50px"
        height="50px"
        viewBox="0 0 462.035 462.035"
      >
        <g>
          <path d="M457.83,158.441c-0.021-0.028-0.033-0.058-0.057-0.087l-50.184-62.48c-0.564-0.701-1.201-1.305-1.879-1.845   c-2.16-2.562-5.355-4.225-8.967-4.225H65.292c-3.615,0-6.804,1.661-8.965,4.225c-0.678,0.54-1.316,1.138-1.885,1.845l-50.178,62.48   c-0.023,0.029-0.034,0.059-0.057,0.087C1.655,160.602,0,163.787,0,167.39v193.07c0,6.5,5.27,11.771,11.77,11.771h438.496   c6.5,0,11.77-5.271,11.77-11.771V167.39C462.037,163.787,460.381,160.602,457.83,158.441z M408.516,134.615l16.873,21.005h-16.873   V134.615z M384.975,113.345v42.274H296.84c-2.514,0-4.955,0.805-6.979,2.293l-58.837,43.299l-58.849-43.305   c-2.023-1.482-4.466-2.287-6.978-2.287H77.061v-42.274H384.975z M53.523,155.62H36.65l16.873-21.005V155.62z M438.498,348.69H23.54   V179.16h137.796l62.711,46.148c4.15,3.046,9.805,3.052,13.954-0.005l62.698-46.144h137.799V348.69L438.498,348.69z" />
        </g>
      </svg>
      <span>{text}</span>
    </div>
  );
}

export default class BibanVisitorSearchForm extends React.Component {
  state = {
    majors: [],
    cities: [],
    sectors: [],
    companySpecialMajors: [],
    jobsTitle: [],
    formValues: INITIAL_VALUES,
  };

  async componentDidMount() {
    const listOfAllRequests = Object.values(
      bibanVisitorServices
    ).map((service) => service());
    try {
      const [
        majors,
        cities,
        sectors,
        companySpecialMajors,
        jobsTitle,
      ] = await Promise.all(listOfAllRequests);
      this.setState({
        majors: JSON.parse(majors.pMajor),
        cities: JSON.parse(cities.cities),
        sectors: JSON.parse(sectors.sectors),
        companySpecialMajors: JSON.parse(companySpecialMajors.Cs),
        jobsTitle: JSON.parse(jobsTitle.jobs),
      });
    } catch (error) {
      console.log(error?.response?.data || error);
    }
  }

  handleChange = (value, option) => {
    this.setState({
      ...this.state,
      formValues: {
        ...this.state.formValues,
        [option]: value,
      },
    });
  };

  render() {
    return (
      <div className="BibanVisitor__wrapper">
        <div className="BibanVisitor__search-form-wrapper">
          <div className="BibanVisitor__search-form__header">
            <img src={brand} alt="brand" className="brand" />
          </div>
          <div className="BibanVisitor__search-form__body">
            <h3>ادخل رغباتك لاختيار الكفاءات المناسبة</h3>
            <img src={MobileAndHand} alt="MobileAndHand" className="image" />
            <Formik
              initialValues={INITIAL_VALUES}
              onSubmit={(values) => {
                if (!this.state.formValues.jobCategory)
                  return notification.error({
                    message: "يجب اختيار المسمى الوظيفي على الاقل",
                  });
                this.props.history.push({
                  pathname: "/biban-visitor/matched-users",
                  search: `?${new URLSearchParams(this.state.formValues)}`,
                });
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Item label="نشاط العمل">
                    <Select
                      name="project"
                      onChange={(value) => this.handleChange(value, "project")}
                      showSearch
                      notFoundContent={
                        <EmptyComponent text="لا يوجد نشاطات عمل متاحة" />
                      }
                      placeholder="أدخل نشاط عملك"
                      filterOption={(inputValue, option) => {
                        return option.props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      }}
                    >
                      {this.state.companySpecialMajors.map((major) => (
                        <Select.Option value={major._id}>
                          {major.specialistName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="القطاع">
                    <Select
                      name="project"
                      onChange={(value) => this.handleChange(value, "sector")}
                      showSearch
                      placeholder="أدخل قطاع عملك"
                      filterOption={(inputValue, option) => {
                        return option.props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      }}
                    >
                      {this.state.sectors.map((sector) => (
                        <Select.Option value={sector._id}>
                          {sector.sectorName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="التخصص المطلوب">
                    <Select
                      name="project"
                      onChange={(value) => this.handleChange(value, "major")}
                      showSearch
                      notFoundContent={
                        <EmptyComponent text="لا يوجد تخصصات متاحة" />
                      }
                      placeholder="أدخل التخصص المطلوب"
                      filterOption={(inputValue, option) => {
                        return option.props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      }}
                    >
                      {this.state.majors.map((major) => (
                        <Select.Option value={major._id}>
                          {major.majorName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="المسمى الوظيفي" required>
                    <Select
                      name="project"
                      onChange={(value) =>
                        this.handleChange(value, "jobCategory")
                      }
                      showSearch
                      notFoundContent={
                        <EmptyComponent text="لا يوجد تخصصات متاحة" />
                      }
                      placeholder="أدخل التخصص المطلوب"
                      filterOption={(inputValue, option) => {
                        return option.props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      }}
                    >
                      {this.state.jobsTitle.map((job) => (
                        <Select.Option value={job._id}>
                          {job.jobName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="المدينة">
                    <Select
                      name="project"
                      onChange={(value) => this.handleChange(value, "city")}
                      showSearch
                      notFoundContent={
                        <EmptyComponent text="لا يوجد مدن متاحة" />
                      }
                      placeholder="أدخل المدينة"
                      filterOption={(inputValue, option) => {
                        return option.props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      }}
                    >
                      {this.state.cities.map((city) => (
                        <Select.Option value={city._id}>
                          {city.cityName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="الجنس">
                    <Select
                      name="project"
                      onChange={(value) => this.handleChange(value, "gender")}
                      placeholder="حدد الجنس"
                    >
                      <Select.Option value="ذكر">ذكر</Select.Option>
                      <Select.Option value="انثى">انثى</Select.Option>
                      <Select.Option value="both">ذكر و انثى</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="BibanVisitor__search-form__body__button"
                    >
                      <span>الاطلاع على المرشحين</span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9903 4.22882L10.0475 3.28601L5.3335 8.00004L10.0475 12.7141L10.9904 11.7713L7.21911 8.00004L10.9903 4.22882Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
