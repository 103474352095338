export const JOBS = [
  {
    value: "باريستا",
    label: "باريستا",
  },
  {
    value: "كاشير",
    label: "كاشير",
  },
  {
    value: "منظم",
    label: "منظم",
  },
  {
    value: "خدمة عملاء",
    label: "خدمة عملاء",
  },
  {
    value: "كاتب محتوى",
    label: "كاتب محتوى",
  },
  {
    value: "محاسب",
    label: "محاسب",
  },
  {
    value: "تسويق",
    label: "تسويق",
  },
  {
    value: "جرافيك ديزاين",
    label: "جرافيك ديزاين",
  },
  {
    value: "محاسبة",
    label: "محاسبة",
  },
  {
    value: "الموارد بشرية",
    label: "الموارد بشرية",
  },
  {
    value: "مندوب مبيعات",
    label: "مندوب مبيعات",
  },
  {
    value: "سكرتير تنفيذي",
    label: "سكرتير تنفيذي",
  },
  {
    value: "مندوب توصيل",
    label: "مندوب توصيل",
  },
  {
    value: "الدعم الفني",
    label: "الدعم الفني",
  },
  {
    value: "البرمجة",
    label: "البرمجة",
  },
  {
    value: "إدارة أنظمة شبكات الحاسب الآلي",
    label: "إدارة أنظمة شبكات الحاسب الآلي",
  },
  {
    value: "الوسائط المتعددة",
    label: "الوسائط المتعددة",
  },
  {
    value: "إدارة المكتبات",
    label: "إدارة المكتبات",
  },
];
