import React, { useCallback } from "react";
import axios from "axios";
import { Select, Input } from "antd";
import { debounce } from "lodash";
const { Option } = Select;

const step1Form = (props) => {
  const {
    handleChange,
    handleInputsChange,
    state,
    setState,
    steps,
    current,
  } = props;
  const {
    jobTypeError,
    sectorError,
    companyNameError,
    jobTypes,
    sectors,
    companyName,
    emailError,
    passwordError,
    companyBranchesCountError,
    companyBranchesCount,
  } = state;

  const checkCompanyName = (value) => {
    const re = /^[A-Za-z\u0600-\u06FF]+$/;

    if (re.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const checkEmail = (value) => {
    var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (pattern.test(value)) {

      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (value) => {
    var pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (pattern.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="com-steps-form">
      <div className="form-content">
        <h5 className="section-title">معلومات الشركة</h5>
        <div className="form-fields">
          <span className="info-label">إسم المجموعة أو الشركة</span>
          <Input
            className="single-input"
            onChange={handleInputsChange}
            name="companyName"
            value={companyName}
          />
          <span style={{ color: "red" }}>
            {companyNameError && !state.companyName
              ? companyNameError
              : companyNameError && checkCompanyName(state.companyName)
                ? ""
                : companyNameError}
          </span>
          <label className="info-label">البريد الالكتروني</label>
          <Input
            className="single-input"
            name="email"
            onChange={(event) => {
              const { value } = event.target;

              setState({ email: value });
            }}
            type="email"
            value={state.email}
            onBlur={async () => {
              if (checkEmail(state.email)) {
                setState({ emailError: "" });
                try {
                  await axios.post("/checkemail", { email: state.email });
                  setState({ emailError: "" });
                } catch (error) {
                  setState({ emailError: error.response.data });
                }

                return;
              } else if (!state.email) {
                return setState({ emailError: "البريد الالكتروني مطلوب" });
              } else {
                setState({ emailError: "يجب ادخال ايميل صحيح" });
              }
            }}
          />
          <span style={{ color: "red" }}>
            {emailError
              ? emailError
              : emailError && checkEmail(state.email)
                ? ""
                : emailError}
          </span>
          <span className="info-label">نشاط العمل</span>
          <Select
            name="jobType"
            onChange={(value) => handleChange(value, "jobType")}
            className="country-text"
            value={state.jobType}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {jobTypes.map((elm) => {
              return (
                <Option value={elm.id} key={elm.id}>
                  {elm.value}
                </Option>
              );
            })}
          </Select>
          <span style={{ color: "red" }}>
            {jobTypeError && !state.jobType ? jobTypeError : ""}
          </span>
          <span className="info-label">القطاع</span>
          <Select
            name="sector"
            onChange={(value) => handleChange(value, "sector")}
            className="country-text"
            value={state.sector}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {sectors.map((elm) => {
              return (
                <Option value={elm.id} key={elm.id}>
                  {elm.value}
                </Option>
              );
            })}
          </Select>
          <span style={{ color: "red" }}>
            {sectorError && !state.sector ? sectorError : ""}
          </span>
          <span className="info-label">عدد الفروع</span>
          <Input
            type="number"
            className="single-input"
            onChange={handleInputsChange}
            name="companyBranchesCount"
            value={companyBranchesCount}
          />
          <span style={{ color: "red" }}>
            {companyBranchesCountError && !state.companyBranchesCount
              ? companyBranchesCountError
              : ""}
          </span>
          <label className="info-label">كلمة المرور</label>
          <Input
            className="single-input"
            name="password"
            onChange={handleInputsChange}
            type="password"
            value={state.password}
          />
          <span style={{ color: "red" }}>
            {passwordError && !state.password
              ? passwordError
              : passwordError && validatePassword(state.password)
                ? ""
                : passwordError}
          </span>
          {current < steps && (
            <button
              className="first-step-btn"
              type="primary"
              onClick={props.next}
            >
              التالي - مسؤول التوظيف
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default step1Form;
