import React from "react";
import "./style.scss";
import Image from "../../../images/Page1_header.svg";
import brand from "../../../images/brand.svg";
import { Link } from "react-router-dom";
import bibanBrand from "../../../images/biban-brand.svg";

export default class BibanVisitor extends React.Component {
  render() {
    return (
      <div className="BibanVisitor__wrapper">
        <div className="BibanVisitor__welcome-wrapper">
          <h2>مرحبًا بك في أول منصة للعمل الجزئي في السعودية</h2>
          <img src={brand} alt="brand" className="brand" />
          <img src={Image} alt="Image" className="image" />
          <Link to="/biban-visitor/search-form">
            <button>
              <span style={{ paddingBottom: "5px" }}>ابدا الآن</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9903 4.22878L10.0475 3.28597L5.3335 8L10.0475 12.7141L10.9904 11.7712L7.21911 8L10.9903 4.22878Z"
                  fill="white"
                />
              </svg>
            </button>
          </Link>
          <img src={bibanBrand} className="biban-brand" alt="biban-brand" />
        </div>
      </div>
    );
  }
}
