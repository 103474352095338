import baseRequest from "../../_core";

const CV = {
  getSkills: () => {
    return baseRequest
      .get("/get/skills?type=skill")
      .then((result) => JSON.parse(result.skills));
  },
  getPersonalSkills: () => {
    return baseRequest
      .get("/get/p_skills?type=pskill")
      .then((result) => JSON.parse(result.PersonalS));
  },
  getMajor: () => {
    return baseRequest
      .get("/get/majors?type=major")
      .then((result) => JSON.parse(result.public_Major));
  },
  getSubMajor: (params) => {
    return baseRequest
      .get(`/get/spMajors?id=${params.id}`)
      .then((result) => result);
  },
  getUniversity: () => {
    return baseRequest
      .get("/get/universty?type=university")
      .then((result) => JSON.parse(result.universty));
  },
  getinformation: () => {
    return baseRequest.get("/getuserinfo/edit").then((result) => result);
  },
  updateCV: (params) => {
    return baseRequest.put("/put/userinfo", params).then((result) => {
      return result;
    });
  },
  jobCategories: () =>
    baseRequest
      .get("/get/allJobCategory?type=category")
      .then((result) => JSON.parse(result.jobs)),
};

export default CV;
