import React from "react";
import carImg from "../../images/car.svg";

function CarsSupport() {
  return (
    <div className="skills-wrapper">
      <div className="center-text">
        <img src={carImg} className="modal-img" />
        <h2 className="p-heading">دعم السيارات</h2>
        <p className="p-description financ-content">
          برنامج موجه خصيصًا لكل شخص يملك رخصة قيادة! سواء كنت طالب، موظف، متفرغ
          <br />
          <br />
          نحن ندعمك من خلال تقديم تمويل السيارات! لتتمكّن من ممارسة أعمال
          التوصيل و النقل مع شركات التوصيل في المنصة.
        </p>
      </div>

      <div className="p-description financ-content">
        <p className="p-financ-content"> الفئة المستهدفة:</p>
        <ul>
          <li>الطلاب</li>
          <li>الخريجين</li>
          <li>المتفرغين</li>
          <li>الموظفين براتب لا يزيد عن 20 ألف.</li>
        </ul>

        <p className="p-financ-content"> أهداف الدعم:</p>
        <ul>
          <li>
            تمثيل دور Ptway المسؤولة بتمكين حلول التوظيف حسب المهارة و الهواية.
          </li>
          <li>دخل أساسي أو دخل إضافي. </li>
          <li>تنمية رأس المال البشري في القطاع اللوجستي والسياحي.</li>
          <li>خفض نسبة البطالة.</li>
        </ul>
      </div>
    </div>
  );
}

export default CarsSupport;
