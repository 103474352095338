import React from "react";
import "./style.scss";
import { Steps, Layout } from "antd";
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Step3 from "./steps/stepThree";
import Step4 from "./steps/stepFour";
import Footer from "../../Footer";
import { connect } from "react-redux";
import { userSignup, userInfo } from "../../../store/actions/userAction";
import statatisticsService from "../../../services/statisticsService";
import Header from "../../Header";
import SEO from "../../SEO";
import moment from "moment";
import cvServices from "../../../services/user/cv";

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,32}$/;

const { jobCategories } = cvServices;

const { allCountries, allCities, allMajors } = statatisticsService;
const { Step } = Steps;

class UserSignup extends React.Component {
  state = {
    current: 0,
    city: "",
    country: "",
    countryErr: "",
    cityError: "",
    steps: "",
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const countries = await allCountries();
    if (countries) this.setState({ countries });
    const cities = await allCities();
    if (cities) this.setState({ cities });
    const majors = await allMajors();
    if (majors) this.setState({ majors });
    const categories = await jobCategories();
    this.setState({ categories });
  };

  next = () => {
    const {
      city,
      gender,
      firstName,
      lastName,
      major,
      jobCategory,
      birthDate,
      certificate,
    } = this.state;
    
    let current = this.state.current;
    switch (current) {
      case 0:
        if (!city) {
          this.setState({
            countryError: "هذا الحقل مطلوب",
            cityError: "هذا الحقل مطلوب",
          });
        } else {
          current = this.state.current + 1;
          this.setState({
            current,
            countryError: "",
            cityError: "",
          });
        }
        break;
      case 1:
        if (!birthDate || !firstName || !lastName || !gender) {
          this.setState({
            firstNameError: "هذا الحقل مطلوب",
            lastNameError: "هذا الحقل مطلوب",
            genderError: "هذا الحقل مطلوب",
            dateError: this.state.dateError || "هذا الحقل مطلوب",
          });
        } else {
          current = this.state.current + 1;
          this.setState({
            current,
            firstNameError: "",
            lastNameError: "",
            genderError: "",
            dateError: "",
          });
        }
        break;
      case 2:
        if (!certificate)
          return this.setState({ certificateError: "هذا الحقل مطلوب" });
        if (!["متوسط", "ثانوية"].includes(certificate) && !major)
          return this.setState({ majorError: "هذا الحقل مطلوب" });
        if (!jobCategory)
          return this.setState({ jobCategoryError: "هذا الحقل مطلوب" });

        current = this.state.current + 1;
        this.setState({
          current,
          certificateError: "",
          majorError: "",
          jobCategoryError: "",
        });

        break;
    }
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  handleChange = (value, name) => {
    if (name === "birthDate") {
      const age = moment().diff(value, "years");
      if (age <= 15) {
        return this.setState({
          dateError: "يجب أن يكون عمرك أكبر من 15 عاماً",
        });
      } else {
        return this.setState({
          birthDate: value,
          dateError: "",
        });
      }
    }
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleWebDateChange = (value, name) => {
    const age = moment().diff(value, "years");
    if (age <= 14) {
      this.setState({
        dateError: "يجب أن يكون عمرك أكبر من 15 عاماً",
      });
    } else {
      this.setState({
        [name]: value,
        dateError: "",
      });
    }
  };

  handleMobileDateChange = (e) => {
    const { name, value } = e.target;
    const age = moment().diff(value, "years");
    if (age <= 16) {
      this.setState({
        dateError: "يجب أن يكون عمرك أكبر من 15 عاماً",
      });
    } else {
      this.setState({
        name: value,
        dateError: "",
      });
    }
  };

  handleInputsChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  signup = async () => {
    const { register, userExtraInfo } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      gender,
      birthDate,
      name,
      city,
      major,
      jobCategory,
      mobile,
      // reEmail,
      rePassword,
    } = this.state;

    if (!email) {
      this.setState({
        emailError: "هذا الحقل مطلوب",
      });
    } else if (!password) {
      this.setState({
        passwordError: "هذا الحقل مطلوب",
      });
    } else if (password !== rePassword) {
      this.setState({
        passwordMatchError: "كلمة المرور غير متطابقة",
      });
    } else if (password && !PASSWORD_REGEX.test(password)){
      this.setState({
        passwordMatchError: "كلمة المرور يجب ان تحتوي على حروف كبيرة وصغيرة وارقام فقط",
      });
    } else {
      await register({
        firstName,
        lastName,
        email,
        password,
      });

      await userExtraInfo({
        gender,
        birthDate: birthDate || name,
        city,
        major,
        mobile,
        jobCategory,
        fullName: firstName + " " + lastName,
      });

      const { history } = this.props;
      history.push("/user/home");
    }
  };

  render() {
    const { current, countries, cities, majors, categories } = this.state;
    const { user } = this.props;

    const steps = [
      {
        title: "الدولة",
        content: (
          <Step1
            handleChange={this.handleChange}
            handleInputsChange={this.handleInputsChange}
            countries={countries}
            cities={cities}
            state={this.state}
            next={() => this.next()}
            current={current}
            steps={1}
          />
        ),
      },
      {
        title: "معلومات شخصية",
        content: (
          <Step2
            handleChange={this.handleChange}
            handleMobileDateChange={this.handleMobileDateChange}
            handleWebDateChange={this.handleWebDateChange}
            handleRadioChange={this.handleInputsChange}
            state={this.state}
            next={() => this.next()}
            prev={() => this.prev()}
            current={current}
            steps={2}
          />
        ),
      },
      {
        // title: 'معلومات شخصية اخرى',
        content: (
          <Step3
            handleChange={this.handleInputsChange}
            handleSelect={this.handleChange}
            majors={majors}
            categories={categories}
            state={this.state}
            next={() => this.next()}
            prev={() => this.prev()}
            current={current}
            steps={3}
          />
        ),
      },
      {
        title: "معلومات الحساب",
        content: (
          <Step4
            handleChange={this.handleInputsChange}
            state={this.state}
            prev={() => this.prev()}
            current={current}
            steps={3}
            signup={this.signup}
            error={user}
            setState={(object) => {
              this.setState(object);
            }}
          />
        ),
      },
    ];
    return (
      <React.Fragment>
        <SEO
          title="الأفراد"
          description=" سجل معنا الآن! وأبدء خطوتك الأولى لكسب تجارب جديدة كل يوم"
        />
        <Header />
        <Layout style={{ background: "#f3f3f3", height: "100%" }}>
          <div className="user-container">
            <div className="signup-form">
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} />
                ))}
              </Steps>
              <div className="steps-header">
                <span>الدولة والمدينة</span>
                <span>معلومات شخصية </span>
                <span>السيرة الذاتية</span>
                <span>معلومات الحساب</span>
              </div>
              <div className="steps-content">{steps[current].content}</div>
            </div>
          </div>
          <Footer />
        </Layout>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    userInfo: state.statistics,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    register: (params) => {
      return dispatch(userSignup(params));
    },
    userExtraInfo: (params) => {
      return dispatch(userInfo(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignup);
