import { Button, Spin, Modal } from "antd";
import React from "react";
import brand from "../../../images/brand.svg";
import defaultUserAvatar from "../../../images/defaultUserAvatar.svg";
import { getmatchedusers } from "../../../services/bibanVisitor";
import config from "../../../_core/config";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import image from "../../../images/Group 740.png";

export default class MatchedUsers extends React.Component {
  state = {
    users: [],
    loading: true,
    showRegisterModal: false,
    showModal: this.props.location.search.includes("activated=true"),
    total: 0,
  };
  searchParams = this.props.location.search
    .slice(1)
    .split("&")
    .reduce((acc, curr) => {
      const [key, value] = curr.split("=");
      acc[key] = value;
      return acc;
    }, {});

  async componentDidMount() {
    try {
      const params = {
        ...this.searchParams,
        page: this.state.currentPage,
        limit: 10,
      };
      const { users, total } = await getmatchedusers({
        ...params,
        gender: this.searchParams.gender
          ? decodeURIComponent(this.searchParams.gender)
          : null,
      });

      this.setState({
        users,
        total,
      });
    } catch (error) {
      console.log(error?.response?.data || error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleShowMore = async () => {
    this.setState({
      showRegisterModal: true,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="BibanVisitor__wrapper">
          <div
            className="BibanVisitor__matched-users-wrapper"
            style={{
              minHeight: "50vh",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spin />
          </div>
        </div>
      );
    }
    return (
      <div className="BibanVisitor__wrapper">
        <Modal
          visible={this.state.showRegisterModal}
          closable={false}
          footer={null}
          width={680}
        >
          <div className="BibanVisitor__matched-users__modal-header">
            <div>
              <img src={image} alt="image" />
              <p>
                مع منصة بتواي ، يمكنك جذب أكثر من 260 ألف باحث عن عمل في المملكة
                العربية السعودية والتفاعل والتواصل معهم
              </p>
            </div>
            <div className="BibanVisitor__matched-users__modal-header__action">
              <h3>
                إذا كنت ترغب في رؤية المزيد والاتصال بأي من هؤلاء الباحثين عن
                عمل ، فقم بإنشاء حساب الآن
              </h3>
              <Button>
                <Link to="/biban-visitor/register">إنشاء حساب</Link>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9903 4.22876L10.0475 3.28595L5.3335 7.99998L10.0475 12.714L10.9904 11.7712L7.21911 7.99998L10.9903 4.22876Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.showModal}
          footer={null}
          width={500}
          onCancel={() => this.setState({ showModal: false })}
        >
          <div className="BibanVisitor__matched-users__qr-modal-header">
            <p>
              يمكننا مساعدتك في الوصول إلى أفضل المرشحين لعرض عملك مع بضع نقرات
              على
            </p>
            <p>www.ptway.net</p>
          </div>
          <div className="BibanVisitor__matched-users__qr-modal-header__action">
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${window.location.origin}/company/signup`}
              alt="qr"
            />
            <h3>امسح كود QR</h3>
            <Button
              onClick={() => {
                this.props.history.push("/biban-visitor");
              }}
              style={{ paddingBottom: "6px" }}
            >
              انهاء
            </Button>
          </div>
        </Modal>
        <div className="BibanVisitor__matched-users-wrapper">
          <div className="BibanVisitor__matched-users__header">
            <img src={brand} alt="brand" className="brand" />
          </div>
          <div className="BibanVisitor__matched-users__body">
            <span className="total">
              {this.state.total} مرشحًا مطابق لنشاط عملك!
            </span>
            <hr />
            <div className="BibanVisitor__matched-users__body__items">
              {this.state.users.map((user) => {
                const AVATAR_URL = user.imagePath.includes("http")
                  ? user.imagePath
                  : ["null", null].includes(user.imagePath)
                  ? defaultUserAvatar
                  : `${config.getBasePublicUrl()}${user.imagePath.slice(1)}`;

                return (
                  <div
                    key={user._id}
                    className="BibanVisitor__matched-users__body__items__item"
                  >
                    <div className="BibanVisitor__matched-users__body__items__item__image">
                      <img
                        src={AVATAR_URL}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultUserAvatar;
                        }}
                      />
                      <div className="BibanVisitor__matched-users__body__items__item__image__user-info-major">
                        <span className="name">{user.fullName}</span>
                        <span className="major">
                          {user.public_Major?.majorName}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="BibanVisitor__matched-users__body__items__item__info">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.7977 18.79V8.62737C18.7977 7.65151 18.0004 6.86004 17.0173 6.86004H13.6861V3.12235C13.6861 2.62302 13.2791 2.219 12.776 2.219H10.4989V0.493111C10.4989 0.221693 10.2776 0 10.0021 0C9.72866 0 9.50532 0.219621 9.50532 0.493111V2.219H7.22814C6.72511 2.219 6.3181 2.62302 6.3181 3.12235V6.86211H2.98685C2.00376 6.86211 1.20643 7.65358 1.20643 8.62944V18.7921H0V20H20V18.7921H18.7977V18.79ZM17.5913 8.95059V18.79H13.6527V8.95059H17.5913ZM11.8576 18.79H8.14235V14.7312C8.14235 13.7387 8.91046 12.8851 9.91025 12.8375C10.9768 12.7877 11.8576 13.631 11.8576 14.6794V18.79ZM10 3.18036C11.0248 3.18036 11.8576 4.00497 11.8576 5.02434C11.8576 6.04372 11.0269 6.86833 10 6.86833C8.97307 6.86833 8.14235 6.04372 8.14235 5.02434C8.14235 4.00497 8.97307 3.18036 10 3.18036ZM2.34398 8.95059H6.28261V18.79H2.34398V8.95059Z"
                          fill="black"
                        />
                      </svg>
                      <span>
                        {user.universty?.universtyName ||
                          "لم يتم تحديد الجامعة"}
                      </span>
                    </div>
                    <div className="BibanVisitor__matched-users__body__items__item__info">
                      <svg
                        width="8"
                        height="15"
                        viewBox="0 0 8 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 0C1.79039 0 0 1.76269 0 3.93813C0 5.41806 1.85292 10.9433 3.03648 14.3227C3.35379 15.2258 4.64852 15.2258 4.96352 14.3227C6.14708 10.9433 8 5.41806 8 3.93813C8 1.76269 6.20961 0 4 0ZM4 6.92764C2.70759 6.92764 1.65837 5.89465 1.65837 4.62223C1.65837 3.3498 2.70759 2.31681 4 2.31681C5.29241 2.31681 6.34163 3.3498 6.34163 4.62223C6.34163 5.89465 5.29473 6.92764 4 6.92764Z"
                          fill="black"
                        />
                      </svg>
                      <span>
                        {user.city?.cityName || "لم يتم تحديد المدينة"}
                      </span>
                    </div>
                    <hr />
                    <p className="BibanVisitor__matched-users__body__items__item__bio">
                      {user.about || "لم يتم إضافة نبذة عن الباحث"}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="BibanVisitor__matched-users__body__show-more">
              <Button
                className="BibanVisitor__matched-users__body__button"
                onClick={this.handleShowMore}
                loading={this.state.gettingMore}
              >
                <span>اكمل رحلة التوظيف</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9903 4.2287L10.0475 3.28589L5.3335 7.99992L10.0475 12.714L10.9904 11.7712L7.21911 7.99992L10.9903 4.2287Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
