export const JOBS = [
  {
    value: "استقبال",
    label: "استقبال",
  },
  {
    value: "تنظيم",
    label: "تنظيم",
  },
];


export const COUNTRIES = [
  {
    value: "قطر",
    label: "قطر",
  },
]
