import { Button, Form, Input, notification } from "antd";
import { Formik } from "formik";
import React from "react";
import brand from "../../../images/brand.svg";
import CongratsImage from "../../../images/congratsImage.svg";
import OTPInput from "react-otp-input";
import {
  baybanrigester,
  baybanvalidate,
  resendOtp,
} from "../../../services/bibanVisitor";

const INITIAL_VALUES = {
  companyName: "",
  email: "",
};

export default class BibanVisitorRegister extends React.Component {
  state = {
    step_one: INITIAL_VALUES,
    step_two: {
      code: "",
    },
    currentStep: 0,
    errors: {
      email: "",
    },
  };

  handleChangeStepOne = (value, option) => {
    this.setState({
      ...this.state,
      step_one: {
        ...this.state.step_one,
        [option]: value.target.value,
      },
    });
  };

  submitStepOne = async () => {
    if (!this.state.step_one.companyName) {
      return notification.error({
        message: "اسم الشركة مطلوب",
      });
    }
    if (!this.state.step_one.email) {
      return notification.error({
        message: "البريد الإلكتروني مطلوب",
      });
    }

    try {
      await baybanrigester(this.state.step_one);
      this.setState({
        ...this.state,
        currentStep: 1,
      });

      notification.success({
        message: "تم إرسال رمز التفعيل بنجاح",
      });
    } catch (error) {
      console.log(error?.response?.data || error);
      this.setState({
        ...this.state,
        errors: {
          email: error?.response?.data,
        },
      });

      notification.error({
        message:
          typeof error?.response?.data === "string"
            ? error?.response?.data
            : "حدث خطأ ما",
      });
    }
  };
  submitStepTwo = async () => {
    try {
      await baybanvalidate({
        otp: this.state.step_two.code,
        email: this.state.step_one.email,
      });
      this.setState({
        ...this.state,
        currentStep: 2,
      });

      notification.success({
        message: "تم تفعيل الحساب بنجاح",
      });
    } catch (error) {
      console.log(error?.response?.data || error);
      notification.error({
        message:
          typeof error?.response?.data === "string"
            ? error?.response?.data
            : "حدث خطأ ما",
      });
    }
  };
  handleResendOtp = async () => {
    try {
      await resendOtp(this.state.step_one);
      notification.success({
        message: "تم اعادة إرسال رمز التفعيل بنجاح",
      });
    } catch (error) {
      console.log(error?.response?.data || error);
      notification.error({
        message:
          typeof error?.response?.data === "string"
            ? error?.response?.data
            : "حدث خطأ ما",
      });
    }
  };

  componentDidUpdate() {
    if (this.state.currentStep === 2) {
      setTimeout(() => {
        this.props.history.push({
          pathname: "/biban-visitor/matched-users",
          search: "?activated=" + true,
        });
      }, 3000);
    }
  }

  render() {
    const tabs = [
      <Formik initialValues={INITIAL_VALUES} onSubmit={this.submitStepOne}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Item label="اسم الشركة">
              <Input
                name="companyName"
                onChange={(value) =>
                  this.handleChangeStepOne(value, "companyName")
                }
                error={this.state.errors.email}
              />
            </Form.Item>
            <Form.Item label="البريد الإلكتروني">
              <Input
                name="email"
                onChange={(value) => this.handleChangeStepOne(value, "email")}
              />
            </Form.Item>
          </Form>
        )}
      </Formik>,
      <Formik initialValues={INITIAL_VALUES} onSubmit={this.submitStepTwo}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="otp-input__wrapper">
              <h3>تحقق من بريدك الإلكتروني للعثور على رمز التفعيل</h3>
              <OTPInput
                separator={<span></span>}
                shouldAutoFocus
                numInputs={4}
                className="otp-input"
                value={this.state.step_two.code}
                onChange={(value) =>
                  this.setState({
                    ...this.state,
                    step_two: {
                      ...this.state.step_two,
                      code: value,
                    },
                  })
                }
              />
              <button className="resend" type="button" onClick={this.handleResendOtp}>
                أعد إرسال رمز التحقق
              </button>
            </div>
          </Form>
        )}
      </Formik>,
      <div className="congrats__wrapper">
        <img
          src={CongratsImage}
          alt="CongratsImage"
          className="CongratsImage"
        />
        <h4>تهانينا</h4>
        <p>تم تفعيل الحساب الخاص بك بنجاح</p>
      </div>,
    ];

    return (
      <div className="BibanVisitor__wrapper">
        <div className="BibanVisitor__register-wrapper">
          <div className="BibanVisitor__register__header">
            <img src={brand} alt="brand" className="brand" />
          </div>

          {this.state.currentStep === 1 && (
            <div
              className="BibanVisitor__register__body__go-back"
              onClick={() => {
                this.setState({
                  ...this.state,
                  currentStep: 0,
                });
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00967 11.7711L5.95248 12.7139L10.6665 7.99983L5.95246 3.28579L5.00965 4.2286L8.78089 7.99984L5.00967 11.7711Z"
                  fill="#595959"
                />
              </svg>
              <span>الى الخلف</span>
            </div>
          )}
          <div className="BibanVisitor__register__body">
            {tabs[this.state.currentStep]}
            {this.state.currentStep !== 2 && (
              <Button
                type="primary"
                htmlType="button"
                className="BibanVisitor__register__body__button"
                onClick={() => {
                  if (this.state.currentStep === 0) {
                    this.submitStepOne();
                  } else {
                    this.submitStepTwo();
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <span style={{ paddingBottom: "6px" }}>
                  {this.state.currentStep === 0 ? "التالي" : "إرسال"}
                </span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9903 4.22894L10.0475 3.28613L5.3335 8.00017L10.0475 12.7142L10.9904 11.7714L7.21911 8.00016L10.9903 4.22894Z"
                    fill="white"
                  />
                </svg>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
