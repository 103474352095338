import React from "react";
import { loadState } from "../../_core/localStorage";
import Footer from "../Footer";
import Header from "../Header";
import CarsSupport from "./Cars";
import Form from "./Form";
import SkillsSupport from "./Skills";
import "./styles.scss";
import { Link, withRouter } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";

class SupportCareer extends React.Component {
  state = {
    tab:
      this.props.history.location.pathname.split("/").at(-1) === "skills"
        ? 1
        : 2,
    showForm:
      this.props.history.location.pathname.split("/").at(-1) !== "support",
  };

  setTab = (tab) => this.setState({ tab });

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (
        this.props.history.location.pathname.split("/").at(-1) === "support"
      ) {
        this.setState({ showForm: false });
      } else {
        this.setState({ showForm: true });
      }
    }
  }

  render() {
    const ActiveComponent = this.state.showForm ? (
      <Form typeOfSupport={this.state.tab === 1 ? "skills" : "cars"} />
    ) : this.state.tab === 1 ? (
      <SkillsSupport />
    ) : (
      <CarsSupport />
    );

    return (
      <>
        <Header hideHeader />
        <div
          className="support-career-wrapper"
          style={{ marginTop: loadState().loggedIn ? "3rem" : "9rem" }}
        >
          <div
            className="tabs"
            style={{
              boxShadow: this.state.showForm ? "" : "inset 0 -2px #d0d0d0",
              height: this.state.showForm ? "130px" : "60px",
            }}
          >
            {this.state.showForm ? (
              <div className="form-header">
                <Tooltip title="العودة إلى دعم المهارات">
                  <button
                    className="back-btn"
                    onClick={() => {
                      this.props.history.push("/support");
                      this.setState({ showForm: false });
                    }}
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.941083 10.0033L0.922945 10.0193L0.908211 10.0384C0.778105 10.2078 0.75 10.3872 0.75 10.5065C0.75 10.7201 0.836622 10.9275 0.998222 11.0715C1.14288 11.2022 1.33125 11.2656 1.52357 11.2468L1.49925 10.998L1.52321 11.2468C1.70958 11.2288 1.87527 11.1373 1.993 10.9942L7.06002 6.42638L7.0761 6.41188L7.08944 6.39484C7.22212 6.22544 7.25 6.04373 7.25 5.92492C7.25 5.75812 7.19714 5.59814 7.0987 5.46584L7.08271 5.44435L7.06252 5.42673L2.00364 1.01121C1.75645 0.706504 1.31517 0.65814 1.01263 0.919895L1.01258 0.919938C0.84667 1.06355 0.755499 1.27399 0.755499 1.49281C0.755499 1.60769 0.780615 1.78366 0.906351 1.95154L0.923141 1.97396L0.944476 1.99211L5.56645 5.92342L0.941083 10.0033Z"
                        fill="#8F8F8F"
                        stroke="#8F8F8F"
                        stroke-width="0.5"
                      />
                    </svg>
                  </button>
                </Tooltip>
                <h3 className="ask-support">للتسجيل</h3>
              </div>
            ) : (
              [1, 2].map((tab) => (
                <button
                  key={tab}
                  onClick={() => this.setTab(tab)}
                  className={this.state.tab === tab ? "active" : ""}
                >
                  {tab === 1 ? "دعم المهارات" : "دعم السيارات"}
                </button>
              ))
            )}
          </div>
          {ActiveComponent}
          {!this.state.showForm && (
            <button
              className="new-project-btn"
              onClick={() => {
                this.setState({ showForm: true });
              }}
            >
              <Link to={`/support/${this.state.tab === 1 ? "skills" : "cars"}`}>
                تقدم بطلب الدعم الآن
              </Link>
            </button>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(SupportCareer);
