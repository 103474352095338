import React, { useEffect } from 'react';
import './style.scss';
import { Row } from 'antd';
import Navbar from '../../../Header/Navbar';
import Footer from '../../../Footer';

export function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Row className="privacy">
        <Navbar />
        <div className="p-header">
          <h2 className="title">
            الشروط والاحكام و إتفاقية المستخدم <br /> Ptway
          </h2>
        </div>
        <div className="privacy-container">
          <h2>
            مهمتنا هي توفير منصة تتيح التواصل بين الشركات الراغبة بالتوظيف
            والطلاب والطالبات الباحثين عن عمل <br />
            مؤقت أو جزئي “المستخدمين”
          </h2>
          <br />
          <br />
          <h3>قائمة المحتويات:</h3>
          <ol>
            <li> المقدمة</li>
            <li> الالتزامات</li>
            <li> الحقوق والحدود</li>
            <li> إخلاء المسؤولية وتحديدها</li>
            <li>الإنهاء</li>
            <li>حل النزاعات</li>
            <li>بنود عامة</li>
            <li>قائمة الامور المسموحة بها والمحظورة الخاصة بــ Ptway</li>
            <li>الشكاوى المتعلقة بالمحتوى</li>
            <li>كيفية الاتصال</li>
          </ol>
          <br />
          <br />
          <h3>1. المقدمة</h3>
          <ul className="contacts-title">
            1.1. العقد
            <ul>
              مجرد تسجيل أو استخدام أو اشتراك أو الوصول إلى منصة PTWay أو
              خدماتها يشكل موافقة منك على هذا العقد وأحكامه، وتبعاً لذلك يجب
              عليك عدم استخدام المنصة في حال لم تكن موافقاً على الأحكام والشروط
              الواردة في هذا العقد. تحتفظ Ptway بحق تعديل أو تغيير هذه الأحكام
              والشروط دون إخطار مسبق، ويكون من مسؤوليتك كمستخدم للمنصة مراجعة
              ضوابط وشروط الاستخدام بشكل دوري لمعرفة التحديثات التي تطرأ على
              الأحكام والشروط هذه، من خلال هذه الصفحة كما نأمل مراجعة سياسة
              الخصوصية لدينا لمعرفة المزيد حول كيفية استخدام Ptway للمعلومات
              التي تقدم لنا من قبل مستخدمي المنصة.
              <h3>الخدمات</h3>
              <p>
                ينطبق هذا العقد على موقع ptway.net وجميع التطبيقات والمنصات التي
                تحمل العلامة التجارية والمواقع والتطبيقات والاتصالات الأخرى
                المرتبطة بـ ـ Ptway والخدمات الأخرى التي تنص على أنها مُقدَّمة
                بموجب هذا العقد"الخدمات"، بما في ذلك مجموعة البيانات الخارجية
                الخاصة بهذه الخدمات، مثل الإعلانات والمكونات الإضافية التقديم
                على Ptway و "المشاركة مع Ptway. المستخدمون المسجلون في خدماتنا
                هم "الأعضاء"، أما المستخدمون غير المسجلين هم "الزائرون" . يسري
                هذا العقد على الأعضاء والزائرين على حدٍ سواء.
              </p>
              <h3>PTway</h3>
              <p>
                يبرم هذا العقد بينك وبين Ptway ( يشار إليكما أيضاً باسم “نحن”
                والضمير “انا” ).{' '}
              </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            1.2. الأعضاء والزائرون
            <ul>
              عندما تسجل وتنضم إلى خدمات Ptway تصبح عضواً وإذا اخترت عدم
              الاشتراك في خدماتنا، يمكنك الوصول إلى ميزات معنية كزائر.
            </ul>
          </ul>
          <ul className="contacts-title">
            1.3. التغيير
            <ul>
              يحق لنا تعديل هذا العقد وسياسة الخصوصية الخاصة بنا وسياسات ملفات
              تعريف الارتباط من حين لآخر. وإذا أجرينا تغييرات جوهرية عليها،
              فسنرسل لك إشعاراً بخدماتنا، أو من خلال وسائل أخرى، لنتيح لك فرصة
              استعراض التغييرات قبل تفعيلها. إذا كنت تعترض على أي تغييرات، يمكنك
              إغلاق حسابك. ويعنى الاستمرار في استخدام خدماتنا بعد نشر أو إرسال
              إشعار بشأن التغييرات التي أجريت على هذه الشروط موافقتك على الشروط
              المحدثة.
            </ul>
          </ul>
          <h3>2. الإلتزامات:</h3>
          <ul className="contacts-title">
            {' '}
            2.1. استحقاق الخدمة
            <ul>
              يحق استخدام الموقع من قبل الاشخاص البالغين الذين لا تقل أعمارهم عن
              الحد الأدنى من العمر المسموح به ذلك أن{' '}
            </ul>
            <p>
              "الخدمات" ليست مصممة للاستخدام من قبل أي شخص دون 15 عامًا. لذا
              لاستخدام الخدمات، يتعين عليك أن توافق على:{' '}
            </p>
            <ol>
              <li>أنه لابد أن تبلغ الحد الأدنى للعمر الموضح سابقاً.</li>
              <li>أن يكون لديك حساب Ptway واحد فقط باسمك واسم شركتك. </li>
            </ol>
          </ul>
          <ul className="contacts-title">
            2.2. حسابك
            <ul>
              ستحافظ على سرية كلمة المرور وعليه فلن تقوم بمشاركة الحساب مع أي
              شخص آخر، وستتبع القواعد والقوانين لدينا.{' '}
              <ol>
                الأعضاء هم أصحاب الحسابات. أنت توافق على:
                <li> محاولة اختيار كلمة مرور قوية وآمنة. </li>
                <li>الحفاظ على أمان وسرية كلمة المرور الخاصة بك.</li>
                <li>
                  {' '}
                  إتباع القانون وقائمة الأمور المسموح بها والمحظورة، أنت مسؤول
                  عن أي شيء يحدث عبر حسابك ما لم تغلقه أو تبلغ عن إساءة استخدام.
                  في علاقتك مع الآخرين (بما في ذلك صاحب العمل)، فإن حسابك ملك
                  لك. ولكن لا يسمح لك بتاتا البيع أو الشراء من خلال الحساب.
                </li>
              </ol>
            </ul>
          </ul>
          <ul className="contacts-title">
            2.3. الدفع
            <ul>
              عليك أن تلتزم بالدفع حسب الآلية المنصوص بها في الموقع ، وأن
              استخدامك لموقع (PTWay) يعني موافقة منك على أن نخزن معلومات الدفع
              الخاصة بك. مع ملاحظة أنه قد تكون هناك رسوم وضرائب تتم إضافتها إلى
              أسعارنا . كما أننا لا نضمن رد الأموال.
              <ul type="disc">
                في حالة شرائك أًيا من خدماتنا المدفوعة “خدمات بريميوم”، فأنت
                توافق على دفع الرسوم والضرائب المطبقة والالتزام بالشروط الإضافية
                الخاصة بالخدمات المدفوعة. قد يؤدي عدم دفع هذه الرسوم إلى إنهاء
                حصولك على الخدمات المدفوعة. كما توافق على الآتي:
                <li>
                  قد تخضع عملية شرائك إلى رسوم صرف العملات الأجنبية أو
                  الاختلافات في الأسعار على أساس الموقع (مثل: أسعار الصرف).{' '}
                </li>
                <li>
                  قد نقوم بتخزين طريقة الدفع ومواصلة خصم الرسوم منها (مثل: بطاقة
                  الائتمان) حتى وإن كانت منتهية، وذلك لتجنب حدوث أي انقطاع في
                  خدماتك.
                </li>
                <li>
                  اذا اشتريت اشتراكا يتم احتساب تكاليف الرسوم و الضرائب الواجبة
                  على تلك الفترة على وسيلة دفعك تلقائًيا في بداية كل فترة
                  اشتراك. لتجنب دفع مقابل الاشتراك القادم، برجاء الإلغاء قبل
                  موعد التجديد.{' '}
                </li>
                <li>
                  يمكنك الحصول على نسخة من فاتورتك من خلال إعدادات حساب “سجل
                  الشراء Ptway خاصتك من "سجل الشراء"
                </li>
              </ul>
            </ul>
          </ul>
          <ul className="contacts-title">
            2.4. الإشعارات ورسائل الخدمة:
            <ul>
              أنت توافق على تقديم الإشعارات إليك من خلال مواقعنا الإلكترونية
              وتطبيقاتنا ومعلومات الاتصال المذكورة بحسابك وتتحمل كامل المسؤولية
              في حال عدم صحة المعلومات المقدمة أو عدم تحديثها.
              <p>
                أنت توافق على أننا سنقوم بإرسال الإخطارات إليك عبر الطرق
                التالية: (1) إشعار داخل الخدمة، أو (2) رسالة يتم إرسالها إلى
                الوسائل الواردة في معلومات الاتصال (مثل: عنوان البريد
                الإلكتروني، رقم الهاتف الجوال، أو العنوان البريدي). أنت توافق
                على تحديث بيانات التواصل الخاصة بك. يرجى مراجعة إعداداتك للتحكم
                في نوعية الرسائل التي تتلقاها منا وتقييدها.{' '}
              </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            2.5. المشاركة:
            <ul style={{ fontWeight: 'bold' }}>
              أنت تقر بأنك عندما تشارك المعلومات، يستطيع الآخرون رؤيتها ونسخها
              واستخدامها.
              <p>
                تتيح “الخدمات” الخاصة بنا إرسال المعلومات في رسائل ومشاركتها
                بطرق عديدة، كملفك الشخصي ومجموعة الشرائح والروابط المؤدية إلى
                منشورات الوظائف ورسائل InMails والمدونات والمقالات الجديدة. قد
                تتاح المعلومات والمحتوى الذي تقوم بمشاركته أو نشره لـ "أعضاء" أو
                لـ "زائرين" أخرين. حيثما أتحنا الإعدادات، سنحترم الاختيارات التي
                اخترتها بشأن َمن بإمكانه مشاهدة المحتوى أو المعلومات (مثل محتوى
                الرسائل المرسلة إلى جهات اتصالك أو مشاركة المحتوى فقط مع زملاء
                Ptway أو تقييد إمكانية رؤية ملفك الشخصي عبر محركات البحث أو
                اختيار عدم إشعار الآخرين بتحديثات ملفك الشخصي على Ptway. بالنسبة
                لأنشطة البحث عن وظيفة، فقد جعلنا الضبط الافتراضي هو عدم إخطار
                زملائك أو العامة. إذا تقدمت إلى وظيفة من خلال خدماتنا أو اخترت
                الإشارة إلى أنك كنت مهتًما بوظيفٍة ما. لسنا ملزمين بنشر أي
                معلومات أو محتوى على الخدمة، ويمكننا إزالتها حسب تقديرنا الخاص
                بإشعار مسبق أو بدونه.
              </p>
            </ul>
          </ul>
          <h3>3. الحقوق و الحدود </h3>
          <ul className="contacts-title">
            3.1. ترخيص المقدم الى Ptway
            <ul style={{ fontWeight: 'bold' }}>
              لك الحق في جميع المحتوى والآراء والمعلومات الشخصية التي توفرها
              لنا، ويحق لنا استخدامها بما لا يخل بالإختيارات التي تحددها بخصوص
              َمن يرى معلوماتك والمحتوى. مع إقرارك بأنك لن تقدم لــ PTWay إلا
              المعلومات والمحتوى التي تملك الحق في مشاركتها معنا.
              <br />
              <br />
              <p>
                ووفًقا للاتفاق بينك أنت و Ptway، أنت تملك المحتويات والمعلومات
                التي ترسلها إلى الخدمات أو تنشرها عليها، وفقط تمنح Ptway
                والشركات التابعة لها الترخيص غير الحصري التالي: الحق العالمي
                القابل للنقل والقابل للترخيص من الباطن الاستخدام ونسخ وتعديل
                وتوزيع ونشر ومعالجة المعلومات و المحتوى الذي تقدمه من خلال
                "الخدمات" بدون أي موافقة أخرى و/او اشعار و/او تقديم تعويض لك أو
                للآخرين، يتم تحديد هذه الحقوق بالطرق التالية:
              </p>
              <p>
                1. يمكنك إنهاء هذا الترخيص الخاص بمحتوى معين عن طريق حذف هذا
                المحتوى من “الخدمات” أو عموًما من خلال إغلاق حسابك إلا (أ)
                بالقدر الذي شاركته مع آخرين كجزء من “الخدمة”، ويمكن الآخرين من
                نسخه أو إعادة مشاركته أو تخزينه و(ب) الوقت الكافي الذي يستغرقه
                لإزالته من أنظمة النسخ الاحتياطي والأنظمة الأخرى.
              </p>
              <p>
                2. لن نجعل المحتوى الخاص بك متاحاً للآخرين في الإعلانات عن
                المنتجات والخدمات الخاصة بآخرين (بما في ذلك، المحتوى المدعوم) من
                دون الحصول على موافقة منفصلة منك. ومع ذلك، يحق لنا - بدون الدفع
                لك أو الآخرين - عرض الإعلانات بالقرب من محتواك ومعلوماتك، وقد
                تكون إجراءاتك على وسائل التواصل الاجتماعي ردا المحتوى المدعوم أو
                صفحات الشركات التي قد تكون مرئية كما هو موضح في “سياسة
                الخصوصية”.
              </p>
              <p>
                3. سنحصل على موافقتك إذا أردنا أن نمنح الأطراف الثالثة حق نشر
                منشوراتك بما يتجاوز الخدمة. ومع ذلك، يجوز للأعضاء و/أو الزوار
                الآخرين الوصول إلى المحتويات والمعلومات ومشاركتها، بما يتوافق مع
                اختياراتك.
              </p>
              <p>
                4. يجوز لنا تحرير محتواك وإجراء تغييرات على تنسيقها (كترجمتها أو
                تعديل حجمها أو تخطيطها أو نوع الملف الخاص بها أو إزالة بيانات
                التعريف)، دون تعديل معنى التعبير الذي تقصده.
              </p>
              <p>
                5. ونظًرا لأنك تملك المحتوى والمعلومات، وأننا فقط لدينا حقوق غير
                حصرية فيها، فإنه يجوز لك اختيار جعلها متاحة للآخرين.
              </p>
              <p>
                أنت توافق على أنه يجوز لنا الوصول إلى أي معلومات تقدمها وتخزنها
                وتستخدمها وفًقا لأحكام سياسة الخصوصية واختياراتك (يما في ذلك
                الإعدادات)
              </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            3.2 توفر الخدمة
            <ul>
              يجوز لنا تغيير “الخدمة” أو تعليقها أو إنهاؤها أو تغيير الأسعار أو
              تعديلها مستقبلاً حسب تقديرنا. قد تكون هذه التغييرات سارية بموجب
              الإشعار المقدم لك إلى الحد المسموح به قانونا.
              <p>
                يجوز لنا تغيير أي من “الخدمات” أو إيقافها. لا نعدك بتخزين أي من
                المعلومات أو المحتويات التي قمت بنشرها أو الحفاظ على ظهورها.
                Ptway ليست خدمة تخزين. أنت توافق على أننا لسنا ملزمين بتخزين أو
                توفير أو الحفاظ على نسخة من أي من المحتويات أو المعلومات التي
                تقدمها أنت أو يقدمها الآخرون، إلا بالقدر الذي يقتضيه القانون
                الساري المفعول، وكما هو موضح في “سياسة الخصوصية”.
              </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            3.3 محتويات المواقع والتطبيقات الأخرى
            <ul>
              باستخدام “الخدمات”، قد تصادف محتويات أو معلومات قد تكون غير دقيقة
              أو غير كاملة أو متأخرة أو مضللة أو غير قانونية أو مسيئة أو ضارة
              بأي شكل من الأشكال. فإن Ptway غير مسؤوله عن أي أضرار أو فيروسات أو
              ما شابه ذلك قد تحصل من الضغط على أو الحصول على محتوى الآخرين.
            </ul>
          </ul>
          <ul className="contacts-title">
            3.4 الحدود
            <ul>
              تحتفظ Ptway بحقها في الحد من استخدامك للخدمات؛ بما في ذلك عدد
              زملائك وقدرتك على التواصل مع أعضاء آخرين. تحتفظ Ptway بالحق في
              تقييد حسابك أو تعليقه أو إنهائه إذا رأت أنك ربما انتهكت هذا
              “العقد” أو خرقت القانون أو أنك تسيء استخدام “الخدمات” (كأن تخرق
              أًيا من الأمور المسموح بها والأمور المحظورة)
            </ul>
          </ul>
          <ul className="contacts-title">
            3.5 حقوق الملكية الفكرية
            <ul>
              تحتفظ Ptway بكل حقوق ملكيتها الفكرية في الخدمات. لا يمنحك استخدام
              “الخدمات” الحق في ملكية “الخدمات” أو المحتوى أو المعلومات المتاحة
              من خلال خدماتنا. العلامات التجارية والشعارات المستخدمة فيما يخص
              الخدمات عبارة عن علامات تجارية لمالكيها .. تعد شعارات Ptway
              والشعارات التي تحتوي على “PT“ وغيرها من العلامات التجارية وعلامات
              الخدمة والرسوم المملوكة لشركة Ptway والشعارات المستخدمة هي علامات
              تجارية أو علامات تجارية مسجلة لشركة يرنو لتقنية المعلومات سجل تجاري (1010947366).
            </ul>
          </ul>
          <h3>4. إخلاء المسؤولية و تحديدها</h3>
          <ul className="contacts-title">
            4.2 لا يوجد ضمان
            <ul>
              تخلي مسئوليتها من أي التزام قانوني بجودة الخدمات أو فعاليتها..
              <p>
                أ. مسؤوليتها عن أي ضمانات وتعهدات ضمنية، (على سبيل المثال:
                ضمانات القابلية للتسويق التجاري، والملاءمة لغرض معين، ودقة
                البيانات وعدم الانتهاك)
              </p>
              <p>ب. لا تضمن Ptway أن الخدمات ستعمل بدون إنقطاع أو أخطاء.</p>
              <p>
                ت. تقدم الخدمات (بما في ذلك المحتوى والمعلومات) على أساس “الحالة
                القائمة” أو “حسب الإتاحة”.
              </p>
              <p>
                لا تسمح بعض القوانين بأنواع محددة من إخلاء المسؤولية، أو قد لا
                تنطبق بعض أو كل انواع إخلاء المسؤولية هذه عليك.
              </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            4.3 إستثناء المسؤولية
            <ul>
              إلى الحد الذي يسمح به القانون (وما لم تبرم Ptway اتفاقية مكتوبة
              منفصلة تلغي هذا العقد)، لن تتحمل Ptway (وأولئك الذين تعمل Ptway
              معهم لتقديم الخدمات) المسؤولية تجاهك أو تجاه آخرين عن أي أضرار غير
              مباشرة، أو عرضية، أو خاصة، أو تبعية، أو تأديبية، أو أي خسارة
              للبيانات أو الفرص أو السمعة الطيبة، أو الأرباح، أو الإيرادات،
              المتعلقة بالخدمات (مثل البيانات المسيئة أو التحريضية أو أوقات
              التوقف أو إهدار الوقت، أو أي استخدام أو تغييرات على معلومات أو
              محتوى لك). يعتبر تحديد المسؤولية هذا جزًءا من أساس الصفقة بينك
              وبين Ptway ، ويسري على كل مطالبات المسؤولية؛ (مثل الضمان
              والمسؤولية التقصيرية والإهمال والعقود والقانون)، وحتى وإن تم إبلاغ
              Ptway أو الشركات التابعة لها بإمكانية تحقق أ ٍّي من هذه الأضرار،
              وحتى وإن لم تحقق هذه التعويضات غرضها الأساسي.
            </ul>
          </ul>
          <h3>5. الانهاء</h3>
          <ul className="contacts-title">
            1.5 يستطع أي منا إنهاء هذا العقد في أي وقت نريده
          </ul>

          <ul className="contacts-title">
            2.5 يجوز لك ولشركة PTway إنهاء هذا العقد في أي وقت بتقدم إشعار للطرف
            الاخر. وعند الإنتهاء ستفقد حق الوصول للخدمات أو استخدامها، يظل مايلي
            نافذاً بعد الإنتهاء
            <br />
            <br />
            <p>5.3.1 حقوقنا في استخدام تعليقاتك والإفصاح عنها </p>
            <p>
              5.3.2 حقوق الأعضاء و/أو الزوار في إعادة نشر معلومات ومحتوى قمت
              بمشاركته عبر الخدمة إلى حد النسخ أو إعادة المشاركة قبل الإنتهاء.
            </p>
            <p>5.3.3 الأقسام 4 و 6 و 7 من هذا العقد. </p>
            <p>
              5.3.4 أي مبالغ مستحقة لأي طرق قبل الإنتهاء تظل مستحقة بعد الإنتهاء{' '}
            </p>
          </ul>
          <ul className="contacts-title">
            3.5 يمكنك زيارة مركز المساعد لإغلاق حسابك{' '}
          </ul>
          <h3>6. حل النزاعات</h3>
          <ul>
            <p>
              تخضع شروط المستخدم الماثلة ويطبق على تسوية أي نزاع أو مطالبة أو
              خلاف ينشأ عن شروط المستخدم الماثلة أو يتعلق بها أو أي انتهاك لها
              أو إنهائها أو تنفيذها أو تفسيرها أو صحتها أو استخدام الموقع أو
              الخدمة أو التطبيق، للقوانين والأنظمة المطبقة في المملكة العربية
              السعودية وتفسر وفقا لها.
            </p>
          </ul>
          <h3>7. بنود عامة</h3>
          <ul>
            <p>
              إذا رأت أي محكمة، ذات سلطة على هذا العقد، أن أي جزء منه غير قابل
              للنفاذ، نقبل نحن وأنت أن تعدل المحكمة هذه البنود كي يصبح هذا الجزء
              قابلاً للنفاذ مع تحقيق الغرض منه. وإذا عجزت المحكمة عن هذا؛ نوافق
              نحن وأنت على أن نطلب من المحكمة حذف الجزء غير القابل للنفاذ،
              وإنفاذ بقية هذا “العقد”. تعتبر النسخة العربية من هذا العقد ملزمة
              وتعد النسخ المترجمة الأخرى لأغراض الملاءمة فقط. يعد هذا العقد
              وملحقاته هو الاتفاقية الوحيدة بيننا في ما يتعلق بالخدمات وتجّب كل
              ما سبقها من اتفاقيات بخصوص “الخدمات”.
              <br />
              إذا لم نتخذ إجراء لمواجهة خرق لهذا العقد؛ فهذا لا يعني أن Ptway قد
              تنازلت عن حقها في إنفاذ هذا العقد. لايحق لك التنازل عن هذا “العقد”
              أو نقله (أو عضويتك أو استخدامك للخدمات) لأي شخص دون موافقتنا. وفي
              ذات الوقت توافق على أنه يجوز لـ Ptway التنازل عن هذا العقد لإحدى
              شركاتها التابعة أو طرف يشتريه دون موافقتك. لا توجد أي جهات خارجية
              مستفيدة من هذا العقد.
              <br />
              نحتفظ بحق تغيير شروط هذا العقد وسنقدم لك إشعاًرا إذا قمنا بهذا.
              إذا لم توافق على هذه التغييرات، يجب أن تتوقف عن استخدام “الخدمات”.
              <br />
              توافق على أن الطريقة الوحيدة لتقديم إشعار قانوني لنا هي على
              العنوان الموضح في القسم رقم 10.
            </p>
          </ul>
          <h3>8 قائمة الامور المسموح بها والمحظورة على PTway </h3>
          <ul className="contacts-title">
            1.8 الامور المسموح بها:
            <ul>
              توافق على مايلي:
              <p>
                1.1.8 الالتزام بجميع القوانين السارية، بما في ذلك عل سبيل المثال
                لا الحصر، قوانين الخصوصية وقوانين الملكية الفكرية، وقوانين
                مكافحة البريد العشوائي، وقوانين التحكم في الصادرات، وقوانين
                الضرائب، والمتطلبات التنظيمية
              </p>
              <p>
                2.1.8 تقديم معلومات دقيقة لنا والحفاظ على تحديثها فيما يخص
                السيرة الذاتية
              </p>
              <p>3.1.8 استخدام اسمك الحقيقي في السيرة الذاتية</p>
              <p>4.1.8 استخدام الخدمات بطريقة احترافية </p>
            </ul>
          </ul>
          <ul className="contacts-title">
            2.8 الامور المحظورة
            <ul>
              توافق على عدم القيام بما يلي:
              <p>
                1.2.8 التصرف بطريقة غير قانونية أو غير مهنية فيما يتعلق
                بخدماتنا، بما في ذلك الاتصاف بعدم الأمانة أو البذاءة أو التمييز.{' '}
              </p>
              <p>
                2.2.8 نشر محتوى غير دقيق أو تشهيري أو فاحش أو مشين أو يحض على
                الكراهية أو مهدد أو أي محتوى غير لائق أو مظالم شخصية أو منازعات
                ناشئة.{' '}
              </p>
              <p>3.2.8 استخدام صورة لا تشبهك أو ليس صورة الرأس لملفك الشخصي.</p>
              <p>
                4.2.8 تحريف الهوية (مثل استخدام اسم مستعار)، أو تحريف وظيفتك أو
                مؤهلاتك أو انتسابك لشخص أو كيان سوا ًء حالًيا أو سابقا.
              </p>
              <p>5.2.8 إنشاء ملف “عضو” لأي شخص سواك (الشخص الطبيعي).</p>
              <p>6.2.8 دعوة الأشخاص الذين لا تعرفهم للانضمام إلى شبكتك.</p>
              <p>7.2.8 استخدام حساب شخص آخر أو محاولة استخدامه.</p>
              <p>8.2.8 مضايقة شخص آخر، أو إساءة التعامل معه، أو إيذاؤه.</p>
              <p>
                9.2.8 إرسال أو نشر أي إعلانات غير مطلوبة أو غير مصرح بها، “بريد
                غير هام” أو “بريد عشوائي” أو “رسالة يطلب إرسالها إلى عدد من
                الأشخاص” أو “مخططات تسويقية هرمية” أو أي شكل من أشكال الطلبات
                غير المصرح بها من Ptway.
              </p>
              <p>
                10.2.8 .تطوير أو دعم أو استخدام برامج أو أجهزة أو برامج نصية أو
                روبوت أو أي طرق أو عمليات أخرى (بما في ذلك المتتبعات أو المكونات
                الإضافية والوظائف الإضافية للمستعرض، أو أي عمل تقني أو يدوي آخر)
                لتعطيل الخدمة أو نسخ ملفات التعريف والبيانات الأخرى من الخدمات
                على خلاف ذلك.
              </p>
              <p>
                11.2.8 تجاوز أي من عناصر تحكم الوصول أو قيود استخدام الخدمة أو
                التحايل عليها (مثل الأحرف خلال عمليات البحث عن كلمات أساسية).
              </p>
              <p>
                12.2.8 نسخ أو استخدام أو الكشف عن أو نشر أي معلومات تم الحصول
                عليها من الخدمات مباشرة أو من خلال أطراف ثالثة (مثل محركات
                البحث) بدون موافقة Ptway
              </p>
              <p>
                13.2.8 طلب عناوين البريد الإلكتروني أو المعلومات الشخصية الأخرى
                من أعضاء لا تعرفهم بدون الحصول على تفويض.
              </p>
              <p>
                14.2.8 استخدام أي بيانات تم الحصول عليها بما يمثل انتهاك أو
                الكشف عنها أو نشرها.
              </p>
              <p>
                15.2.8 الكشف عن معلومات ليس معك تصريح للكشف عنها (مثل معلومات
                الآخرين السرية (بما في ذلك معلومات عن صاحب العمل).
              </p>
              <p>
                16.2.8 انتهاك حقوق الملكية الفكرية للغير بما في ذلك حقوق النشر
                أو براءات الاختراع أو العلامات التجارية أو الأسرار التجارية أو
                غيرها من حقوق الملكية. فعلى سبيل المثال، لا تقم بنسخ أو نشر
                المنشورات أو المحتويات الأخرى التابعة للغير بدون الحصول على
                موافقتهم والتي قد يمنحونها عن طريق النشر بموجب ترخيص المشاع
                الإبداعي (إلا من خلال وظيفة المشاركة المتوفرة).
              </p>
              <p>
                17.2.8 انتهاك حقوق الملكية لـ Ptway أو غيرها من الحقوق بما في
                ذلك على سبيل المثال لا الحصر،
              </p>
              <ul>
                1.17.2.8 نسخ أو نشر مقاطع الفيديو أو المواد الأخرى الخاصة بنا
                <p>
                  2.17.2.8 نسخ أو نشر التكنولوجيا ما لم يتم إطلاقها بموجب تراخيص
                  ذات مصدر مفتوح.
                </p>
                <p>
                  3.17.2.8 استخدام كلمة “Ptway“أو شعاراتنا في أي اسم تجاري أو
                  بريد إلكتروني أو عنوان URL باستثناء ما هو موضح في إرشادات
                  العلامة التجارية.
                </p>
              </ul>
              <p>
                18.2.8 استخدام دعوات Ptway لإرسال رسائل إلى أشخاص لا يعرفونك أو
                من غير المتحمل أن يتعرفوا عليك كجهة اتصال معروفة.
              </p>
              <p>
                19.2.8 نشر أي شيء يتضمن فيروسات تصيب البرامج، أو فيروسات متنقلة
                أو أي رموز ضارة أخرى.
              </p>
              <p>
                20.2.8 استخدام المعرفات بغرض إخفاء منشأ أي رسالة أو منشور يتم
                نقله من خلال “الخدمات”.
              </p>
              <p>
                21.2.8 إنشاء ملفات شخصية أو توفير محتوى يروج لخدمات جنسية أو
                دعارة.
              </p>
              <p>
                22.2.8 إنشاء أو تشغيل مخطط تسويقي هرمي أو القيام بالاحتيال أو
                ممارسات مشابهة أخرى.
              </p>
              <p>
                23.2.8 إجراء الهندسة العكسية لتعليمة البرمجية المصدر للخدمات أو
                أي تقنية مرتبطة بها لا تكون مفتوحة المصدر، أو أي جزء منها أو
                إلغاء تحويلها أو إلغاء تجميعها أو فك تشفيرها أو أي محاولة على
                خلاف ذلك لاشتقاق التعليمة البرمجية المصدر.
              </p>
              <p>
                24.2.8 التعبير، بشكل ضمني أو صريح، بأنك تنتسب إلى Ptway أو أنها
                تصادق عليك بذلك بدون موافقتها الصريحة.
              </p>
              <p>
                25.2.8 تأجير الوصول إلى الخدمات أو أي بيانات مرتبطة بها، أو
                استئجارها أو إقراضها أو المتاجرة بها، أو بيعها/إعادة بيعها.
              </p>
              <p>
                26.2.8 بيع أي خدمة أو رعايتها أو جني أموال منها على خلاف ذلك
                بدون موافقة Ptway.
              </p>
              <p>
                27.2.8 إجراء أي ارتباط داخلي مع خدماتنا لأي غرض بخلاف الترويج
                لملفك الشخصي أو لمجموعة موجودة بخدماتنا بدون موافقة Ptway
              </p>
              <p>
                28.2.8 إزالة أي حقوق نشر أو علامة تجارية أو إشعارات حقوق الملكية
                الأخرى المضمنة في أو على إحدى خدماتنا.
              </p>
              <p>29.2.8 إزالة أي إعلان مضمن في الخدمات أو تغطيته أو إخفاؤه.</p>
              <p>
                30.2.8 استخدام برامج bots أو طرق آلية أخرى للوصول إلى الخدمات،
                أو إضافة جهات اتصال أو تنزيلها، أو إرسال رسائل أو إعادة توجيهها.
              </p>
              <p>
                31.2.8 مراقبة توفر الخدمات أو أدائها أو وظائفها لأي أغراض
                تنافسية.
              </p>
              <p>
                32.2.8 الاشتراك في إستخدام “إطارات” أو “انعكاس”، أو محاكاة مظهر
                أو وظيفة الخدمات بأي طريقة أخرى.
              </p>
              <p>33.2.8 إحلال الخدمات أو مظهرها أو تعديل ذلك بطريقة أخرى.</p>
              <p>
                34.2.8 الوصول إلى الخدمات بطريقة أخرى بخلاف الواجهات التي توفرها
                Ptway صراحة، مثل تطبيقات الجوال.
              </p>
              <p>35.2.8 استخدام الخدمة في مهام غير معدة لأجلها.</p>
              <p>36.2.8 تجاوز أي خصائص أمان للخدمات.</p>
              <p>
                37.2.8 التداخل مع تشغيل الخدمات أو وضع حمل غير مبرر عليها (مثل
                البريد العشوائي، وهجوم الامتناع عن الخدمة، والفيروسات،
                وخوارزميات الألعاب)؛ و/أو
              </p>
              <p>
                38.2.8 انتهاك توجيهات المجتمع المهنية أو أي أحكام أخرى تتعلق
                بخدمة معينة والتي يتم تقديمها عند اشتراكك في مثل هذه الخدمة أو
                البدء في استخدامها.
              </p>
            </ul>
          </ul>
          <h3>9 الشكاوى المتعلقة بالمحتوى</h3>
          <ul>
            <p>
              كما نحترم حقوق الملكية الفكرية للآخرين. ونشترط أن تكون المعلومات
              التي ينشرها الأعضاء دقيقة ولا تنتهك حقوق الملكية الفكرية أو الحقوق
              الأخرى للجهات الخارجية. نوفر سياسة ومستندات للشكاوى المتعلقة
              بالمحتوى المنشور من قبل الأعضاء.
            </p>
          </ul>
          <h3>10 كيفية الاتصال بنا </h3>
          <ul>
            <p>
              إذا كنت ترغب في إرسال إشعارات أو تبليغ بالإجراءات إلينا، يرجى
              الاتصال بنا: على البريد: info@ptway.net
            </p>
          </ul>
        </div>
        <Footer />
      </Row>
    </React.Fragment>
  );
}
