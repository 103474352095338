import React from "react";
import { Modal, Input } from "antd";
import carImg from "../../images/car.svg";

const FinanceCarModal = (props) => {
  return (
    <Modal
      visible={props.postFinanceCarPopup}
      onCancel={props.closable}
      footer={false}
    >
      <div className="new-project">
        <div>
          <img src={carImg} className="modal-img" />

          <h2 className="p-heading">دعم السيارات</h2>
          <p className="p-description financ-content">
            برنامج موجه خصيصًا لكل شخص يملك رخصة قيادة! سواء كنت طالب، موظف،
            متفرغ
          </p>

          <div className="p-description financ-content">
            <p className="">
              نحن ندعمك من خلال تقديم تمويل السيارات! لتتمكّن من ممارسة أعمال
              التوصيل و النقل مع شركات التوصيل في المنصة.
            </p>
          </div>
        </div>

        <div className="p-description financ-content">
          <p className="p-financ-content"> الفئة المستهدفة:</p>
          <ul>
            <li>الطلاب.</li>
            <li>الخريجين.</li>
            <li>المتفرغين.</li>
            <li>الموظفين براتب لا يزيد عن 20 ألف.</li>
          </ul>
          <p className="p-financ-content"> أهداف الدعم:</p>
          <ul>
            <li>
              تمثيل دور Ptway المسؤولة بتمكين حلول التوظيف حسب المهارة و
              الهواية.
            </li>
            <li>دخل أساسي أو دخل إضافي.</li>
            <li>تنمية رأس المال البشري في القطاع اللوجستي والسياحي.</li>
            <li>خفض نسبة البطالة.</li>
          </ul>
        </div>

        <button className="new-project-btn">
          <a
            href="https://len.sdb.gov.sa/Guest/Login.aspx?key=6632b16f-8ad9-449e-88c9-94c88c615d40"
            className="financ-btn"
          >
            تقدم بطلب الدعم الآن
          </a>
        </button>
      </div>
    </Modal>
  );
};

export default FinanceCarModal;