import * as yup from "yup";

const validationSchema = yup.object().shape({
  project: yup.string().required("هذا الحقل مطلوب"),
  jobCategory: yup.string().required("هذا الحقل مطلوب"),
  jobTitle: yup.string().required("هذا الحقل مطلوب"),
  workHours: yup
    .number()
    .min(1, "يجب ان يكون عدد ساعات العمل بين 1 و 8")
    .max(8, "يجب ان يكون عدد ساعات العمل بين 1 و 8")
    .required("هذا الحقل مطلوب"),
  gender: yup.string().required("هذا الحقل مطلوب"),
  salary: yup.number().min(1500, "الحد الشهري الأدنى هو 1500"),
  workDays: yup
    .mixed()
    .oneOf([2, 3, 4, 5, 6])
    .required("هذا الحقل مطلوب"),
  // date: yup.string().required("هذا الحقل مطلوب"),
  required_Number: yup
    .number()
    .min(1, "يجب ان يكون العدد الطلوب  بين 1 و 7")
    .max(7, "يجب ان يكون العدد المطلوب  بين 1 و 7")
    .required("هذا الحقل مطلوب"),
  jobDescription: yup.string().required("هذا الحقل مطلوب"),
  personalSkills: yup.array().required("هذا الحقل مطلوب").min(1, "يجب اختيار مهارة واحدة على الأقل"),
  country: yup.string().required("هذا الحقل مطلوب"),
  city: yup.string().required("هذا الحقل مطلوب"),
});

export default validationSchema;
