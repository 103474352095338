const config = {};
let baseUrl;

config.getBasePublicUrl = () => {
  if (window.location.href.includes("biban")) {
    return "http://20.74.250.30/";
  }
  if (process.env.NODE_ENV === "development") {
    baseUrl = "http://20.74.250.30/";
  } else if (process.env.NODE_ENV === "production") {
    baseUrl = "http://20.74.250.30/";
  } else {
    baseUrl = "http://20.74.250.30/";
  }
  // todo: delete the line below
  return baseUrl;
};

export default config;
