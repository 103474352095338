import React from "react";
import { notification, Spin } from "antd";
import { Formik } from "formik";
import validationSchema from "./validation";
import { Form, Input, Button } from "antd";
import formImg from "../../images/support-form.svg";
import baseRequest from "../../_core";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

class FormComponent extends React.Component {
  state = {
    ...initialValues,
    loading: false,
  };

  handleSubmitForm = async (inputsData) => {
    if (this.state.loading)
      return notification.warning({ message: "انتظر قليلا" });

    const url =
      this.props.typeOfSupport === "skills"
        ? "https://len.sdb.gov.sa/Guest/Login.aspx?key=04ab6080-87c1-4456-aef7-5ed7bdd357cd"
        : "https://len.sdb.gov.sa/Guest/Login.aspx?key=6632b16f-8ad9-449e-88c9-94c88c615d40";
    try {
      this.setState({ loading: true });
      await baseRequest.post("/sdbrigesterUser", {
        ...inputsData,
        typeOfSupport: this.props.typeOfSupport,
      });

      notification.success({
        message: "تم ارسال الدعم بنجاح",
      });

      window.location.replace(url);
    } catch (error) {
      console.log(error.response.data);

      notification.error({
        message: "خطأ في طلب الدعم",
        description:
          error.response.data ||
          "حدث خطأ أثناء تقديم طلب الدعم، يرجى المحاولة مرة أخرى",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div className="form-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmitForm}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Form.Item
                  label="الاسم الأول"
                  validateStatus={
                    errors.firstName && touched.firstName && "error"
                  }
                >
                  <Input
                    value={values.firstName}
                    onChange={handleChange}
                    name="firstName"
                    type="text"
                    onBlur={handleBlur}
                  />
                </Form.Item>
                {errors.firstName && touched.firstName && (
                  <span className="error-message">{errors.firstName}</span>
                )}
              </div>
              <div>
                <Form.Item
                  label="الاسم الأخير"
                  validateStatus={
                    errors.lastName && touched.lastName && "error"
                  }
                >
                  <Input
                    value={values.lastName}
                    onChange={handleChange}
                    name="lastName"
                    type="text"
                    onBlur={handleBlur}
                  />
                </Form.Item>
                {errors.lastName && touched.lastName && (
                  <span className="error-message">{errors.lastName}</span>
                )}
              </div>

              <div>
                <Form.Item
                  label="البريد الالكتروني"
                  validateStatus={errors.email && touched.email && "error"}
                >
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    name="email"
                    type="email"
                    onBlur={handleBlur}
                  />
                </Form.Item>
                {errors.email && touched.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>

              <div className="actions">
                <button type="submit" disabled={this.state.loading}>
                  {this.state.loading && <Spin size="small" />}
                  {this.state.loading ? "انتظر قليلا" : "انتقل لصفحة الدعم"}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <img src={formImg} className="modal-img" />
      </div>
    );
  }
}

export default FormComponent;
