import React from "react";
import { Select } from "antd";
import personalInfoIcon from "../../../../images/personal-info-icon.svg";
import _ from "lodash";

const { Option } = Select;

const step3Form = (props) => {
  const { handleSelect, majors, categories, state, current, steps } = props;
  const { majorError, jobCategoryError, certificateError } = state;

  return (
    <div className="steps-form steps-form-user-signin">
      <div className="form-content signupf">
        <img src={personalInfoIcon} alt="" />
        <span className="f-title">معلومات شخصية</span>
        <span className="line"></span>
        <div className="form-fields">
          <label className="info-label">الشهادة</label>
          <Select
            onChange={(value) => handleSelect(value, "certificate")}
            className="major-text"
            value={state.certificate}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="متوسط">متوسط</Option>
            <Option value="ثانوية">ثانوية</Option>
            <Option value="دبلوم">دبلوم</Option>
            <Option value="بكالوريوس">بكالوريوس</Option>
            <Option value="ماجستير">ماجستير</Option>
            <Option value="دكتوراة">دكتوراة</Option>
          </Select>
          {certificateError && (
            <span style={{ color: "red" }}>{certificateError}</span>
          )}

          {!["متوسط", "ثانوية"].includes(state.certificate) && (
            <>
              <label className="info-label">التخصص العام</label>
              <Select
                onChange={(value) => handleSelect(value, "major")}
                className="major-text"
                value={state.major}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {_.isArray(majors)
                  ? majors.map((elm) => {
                    return (
                      <Option value={elm.id} key={elm.id}>
                        {elm.value}
                      </Option>
                    );
                  })
                  : ""}
              </Select>
              <span style={{ color: "red" }}>
                {majorError && !state.major ? majorError : ""}
              </span>
            </>
          )}

          <label className="info-label">الإهتمامات الوظيفية</label>
          <Select
            onChange={(value) => handleSelect(value, "jobCategory")}
            className="major-text"
            value={state.jobCategory}
            mode="multiple"
            showArrow={true}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {_.isArray(categories)
              ? categories.map((elm) => {
                return (
                  <Option value={elm._id} key={elm._id}>
                    {elm.jobName}
                  </Option>
                );
              })
              : ""}
          </Select>
          {jobCategoryError && (
            <span style={{ color: "red" }}>{jobCategoryError}</span>
          )}

          <div className="steps-btns">
            {current > 0 && (
              <button
                // style={{ marginLeft: 8 }}
                onClick={props.prev}
                className="prev-btn prev-btn-mob"
              >
                السابق
              </button>
            )}
            {current < steps && (
              <button
                className="first-step-btn"
                type="primary"
                onClick={props.next}
              >
                التالي
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default step3Form;
