import * as yup from "yup";

export default yup.object().shape({
  firstName: yup
    .string("الاسم الأول يجب أن يكون نص")
    .required("الاسم الأول مطلوب"),
  lastName: yup
    .string("الاسم الأخير يجب أن يكون نص")
    .required("الاسم الأخير مطلوب"),
  email: yup
    .string("البريد الالكتروني يجب أن يكون نص")
    .email("البريد الالكتروني غير صحيح")
    .required("البريد الالكتروني مطلوب"),
});
