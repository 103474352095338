import React from "react";
import { Input } from "antd";
import axios from "axios";

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,32}$/;

const step4Form = (props) => {
  const { handleChange, state, current, steps, setState } = props;

  const {
    emailError,
    passwordError,
    mobileError,
    // emailMatchError,
    passwordMatchError,
  } = state;

  const checkEmail = (value) => {
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    if (pattern.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="steps-form steps-form-user-signin">
      <div className="form-content signupf">
        <span className="f-title">معلومات الحساب</span>
        <span className="line"></span>
        <div className="form-fields">
          <div className="first-row">
            <div className="elements">
              <label className="info-label">البريد الالكتروني</label>
              <Input
                className="name-text"
                name="email"
                onChange={handleChange}
                type="email"
                value={state.email}
                // onBlur={(event) => {
                //   const email = event.target.value;

                //   if (email && !EMAIL_REGEX.test(email)) {
                //     setState({
                //       ...state,
                //       emailError: "البريد الالكتروني غير صحيح",
                //     });
                //   } else if (email && EMAIL_REGEX.test(email)) {
                //     setState({ ...state, emailError: "" });
                //   } else {
                //     setState({ ...state, emailError: "هذا الحقل مطلوب" });
                //   }
                // }}
                onBlur={async () => {
                  if (checkEmail(state.email)) {
                    setState({ emailError: "" });
                    try {
                      await axios.post("/checkemail", { email: state.email });
                      setState({ emailError: "" });
                    } catch (error) {
                      setState({ emailError: error.response.data });
                    }
    
                    return;
                  } else if (!state.email) {
                    return setState({ emailError: "البريد الالكتروني مطلوب" });
                  } else {
                    setState({ emailError: "يجب ادخال ايميل صحيح" });
                  }
                }}
              />
              <span className="help-text" style={{ color: "red" }}>
                {emailError || !state.email ? emailError : ""}
              </span>
            </div>
            <div className="elements">
              <label className="info-label">رقم الموبايل</label>
              <Input
                className="name-text"
                name="mobile"
                onChange={handleChange}
                type="telephone"
                value={state.mobile}
                // onBlur={(event) => {
                //   const email = event.target.value;

                //   if (email && !EMAIL_REGEX.test(email)) {
                //     setState({
                //       ...state,
                //       emailError: "البريد الالكتروني غير صحيح",
                //     });
                //   } else if (email && EMAIL_REGEX.test(email)) {
                //     setState({ ...state, emailError: "" });
                //   } else {
                //     setState({ ...state, emailError: "هذا الحقل مطلوب" });
                //   }
                // }}
                onBlur={async () => {
                  if (state.mobile.length < 6) {
                    return setState({ mobileError: "رقم الموبايل خاطيء" });
                  }else{
                    return setState({ mobileError: false })
                  } 
                }}
              />
              <span className="help-text" style={{ color: "red" }}>
                {mobileError || !state.mobile ? mobileError : ""}
              </span>
            </div>
          </div>
          <div className="first-row">
            <div className="elements">
              <label className="info-label">كلمة المرور</label>
              <Input
                className="name-text"
                name="password"
                onChange={(event) => {
                  setState({
                    ...state,
                    passwordError: "",
                  });

                  handleChange(event);
                }}
                type="password"
                value={state.password}
                onBlur={(event) => {
                  const password = event.target.value;
                  if (!password)
                    return setState({
                      ...state,
                      passwordError: "هذا الحقل مطلوب",
                    });

                  if (password && password.length < 8) {
                    setState({
                      ...state,
                      passwordError: "كلمة المرور يجب ان تكون اكثر من 8 احرف",
                    });
                  } else if (password && password.length >= 32) {
                    setState({
                      ...state,
                      passwordError: "كلمة المرور يجب ان تكون اقل من 32 حرف",
                    });
                  } else if (password && !PASSWORD_REGEX.test(password)) {
                    setState({
                      ...state,
                      passwordError:
                        "كلمة المرور يجب ان تحتوي على حروف كبيرة وصغيرة وارقام فقط",
                    });
                  } else {
                    setState({ ...state, passwordError: "" });
                  }
                }}
              />
              <span className="help-text" style={{ color: "red" }}>
                {passwordError || !state.password ? passwordError : ""}
              </span>
            </div>
            <div className="elements">
              <label className="info-label">تأكيد كلمة المرور</label>
              <Input
                className="name-text"
                name="rePassword"
                onChange={handleChange}
                type="password"
              />
              <span className="help-text" style={{ color: "red" }}>
                {state.password !== state.rePassword || !state.rePassword
                  ? passwordMatchError
                  : ""}
              </span>
            </div>
          </div>
          {props.error && props.error.signupError && (
            <span style={{ color: "red" }}>
              {props.error.signupError.response.data}
            </span>
          )}

          <div className="steps-btns">
            {current > 0 && (
              <button
                // style={{ marginLeft: 8 }}
                onClick={props.prev}
                className="prev-btn prev-btn-mob"
              >
                السابق
              </button>
            )}
            {current === steps && (
              <button
                type="primary"
                className="first-step-btn"
                onClick={props.signup}
                // onClick={() => this.props.history.push('/user/home')}
              >
                الانتقال للرئيسية
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default step4Form;
