import React from "react";
import "./style.scss";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import Footer from "../Footer";
import LoginNavbar from "../Header/LoginNavbar";
import validationSchema from "./schema";
import { JOBS, COUNTRIES } from "./constants";

import { Formik } from "formik";
import baseRequest from "../../_core";
import { CloudUploadOutlined } from "@ant-design/icons";

const initialValues = {
  fullName: "",
  mobile: "",
  email: "",
  selectedJobs: [],
  cv: null,
  city: "قطر",
};

class QatarForm extends React.Component {
  state = {
    ...initialValues,
    loading: false,
  };

  handleChange = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  };

  handleMultipleSelectChange = (selectedJobs) => {
    this.setState({ selectedJobs });
  };

  handleSubmitForm = async (inputsData) => {
    this.setState({ loading: true });

    const formData = new FormData();

    Object.keys(inputsData).forEach((key) => {
      if (Array.isArray(inputsData[key])) {
        inputsData[key].forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      await baseRequest.post("/qatar/newrequest", formData);

      notification.success({
        message: "تم ارسال طلبك بنجاح",
        description: "سيتم التواصل معك قريبا",
      });
    } catch (error) {
      console.log(error?.response);

      notification.error({
        message: "خطأ",
        description:
          error?.response?.data?.message ||
          "حدث خطأ ما، يرجى المحاولة مرة أخرى",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <LoginNavbar />
        <Row>
          <Col md={4}></Col>
          <Col md={15} className="jobs-new-form">
            <h2>هذا وقتك، سجل معنا!</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmitForm}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleBlur,
                setErrors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Form.Item
                      label="الاسم كامل"
                      validateStatus={
                        errors.fullName && touched.fullName && "error"
                      }
                      className="form-item"
                    >
                      <Input
                        value={values.fullName}
                        onChange={handleChange}
                        name="fullName"
                        type="text"
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                    {errors.fullName && touched.fullName && (
                      <span className="error-message">{errors.fullName}</span>
                    )}
                  </div>
                  <div className="half-row">
                    <div>
                      <Form.Item
                        label="رقم الجوال"
                        validateStatus={
                          errors.mobile && touched.mobile && "error"
                        }
                        className="form-item"
                      >
                        <Input
                          value={values.mobile}
                          onChange={(event) => {
                            if (!event.target.value) handleChange(event);

                            if (/^\d+$/.test(event.target.value)) {
                              handleChange(event);
                            }
                          }}
                          name="mobile"
                          type="text"
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                      {errors.mobile && touched.mobile && (
                        <span className="error-message">{errors.mobile}</span>
                      )}
                    </div>
                    <div>
                      <Form.Item
                        label="البريد الالكتروني"
                        validateStatus={
                          errors.email && touched.email && "error"
                        }
                        className="form-item"
                      >
                        <Input
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          type="text"
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                      {errors.email && touched.email && (
                        <span className="error-message">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="half-row">
                    <div>
                      <Form.Item
                        label="الوظائف"
                        validateStatus={errors.jobs && touched.jobs && "error"}
                        className="form-item"
                      >
                        <Select
                          mode="multiple"
                          placeholder="قم باختيار الوظائف"
                          onChange={(_, event) => {
                            const ids = event.map((elm) => elm.key);
                            setFieldValue("selectedJobs", ids);

                            this.handleMultipleSelectChange(_, ids);
                          }}
                          optionLabelProp="label"
                          value={this.state.selectedJobs}
                          className={
                            errors.selectedJobs && touched.selectedJobs
                              ? "multiple-select-error"
                              : ""
                          }
                        >
                          {JOBS.map((job) => (
                            <Select.Option
                              key={job.value}
                              value={job.value}
                              label={job.label}
                            >
                              {job.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {errors.selectedJobs && touched.selectedJobs && (
                        <span className="error-message">
                          {errors.selectedJobs}
                        </span>
                      )}
                    </div>
                    <div>
                      <Form.Item
                        label="المدينة"
                        validateStatus={errors.city && touched.city && "error"}
                        className="form-item"
                      >
                        <Select
                          mode="Single"
                          name="country"
                          disabled={true}
                          placeholder="قطر"
                          onChange={(_, event) => {
                            setFieldValue("city", "قطر");

                            this.setState({ city: "قطر" });
                          }}
                          optionLabelProp="label"
                          value={this.state.city}
                         
                        >
                          {COUNTRIES.map((job) => (
                            <Select.Option
                              key={job.value}
                              value={job.value}
                              label={job.label}
                            >
                              {job.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {errors.selectedJobs && touched.selectedJobs && (
                        <span className="error-message">
                          {errors.selectedJobs}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="cv-item ant-form-item-label">
                    <label className="cv-label">تحميل السيرة الذاتية</label>
                    <Upload
                      name="cv"
                      beforeUpload={(file) => {
                        if (this.state.cv) {
                          notification.error({
                            message: "خطأ",
                            description: "يمكنك تحميل ملف واحد فقط",
                          });

                          return;
                        }
                        if (!file)
                          return setErrors({
                            ...errors,
                            cv: "يرجى اختيار ملف",
                          });
                        if (file.type !== "application/pdf")
                          return setErrors({
                            ...errors,
                            cv: "يجب ان يكون الملف بصيغة pdf",
                          });

                        setFieldValue("cv", file);
                        this.setState({ cv: file });
                        return false;
                      }}
                      onRemove={() => {
                        setFieldValue("cv", null);
                        this.setState({ cv: null });
                      }}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}
                      accept=".pdf"
                      multiple={false}
                    >
                      <Button htmlType="button" className="upload-button">
                        <CloudUploadOutlined />
                      </Button>
                    </Upload>
                    {!this.state.cv && (
                      <span className="help-text">
                        ملف السيرة الذاتية يجب أن يكون PDF
                      </span>
                    )}
                    {errors.cv && touched.cv && (
                      <span className="error-message">{errors.cv}</span>
                    )}
                  </div>
                  <Button
                    htmlType="submit"
                    className="submit-btn"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && <Spin size="small" />}
                    {this.state.loading ? "انتظر قليلا" : "ارسال"}
                  </Button>
                </form>
              )}
            </Formik>
          </Col>
        </Row>
        <Footer />
      </React.Fragment>
    );
  }
}

export default QatarForm;
